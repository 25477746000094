import { SvgIcon } from '@mui/material';
import React from 'react';

const GoogleSignUp = ({ width, height }) => {
    return (
        <SvgIcon sx={{ width, height }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_842_3485)">
                    <path d="M19.2456 8.26113L11.0878 8.26074C10.7275 8.26074 10.4355 8.5527 10.4355 8.91293V11.519C10.4355 11.8791 10.7275 12.1712 11.0877 12.1712H15.6817C15.1787 13.4767 14.2398 14.57 13.0419 15.2647L15.0007 18.6557C18.143 16.8384 20.0007 13.6497 20.0007 10.0803C20.0007 9.57203 19.9633 9.20871 19.8884 8.79961C19.8314 8.48879 19.5616 8.26113 19.2456 8.26113Z" fill="#167EE6" />
                    <path d="M9.99957 16.0871C7.75137 16.0871 5.78871 14.8587 4.73461 13.041L1.34375 14.9955C3.06934 17.9862 6.30191 20.0001 9.99957 20.0001C11.8135 20.0001 13.5251 19.5117 14.9996 18.6606V18.6559L13.0407 15.2649C12.1447 15.7846 11.1078 16.0871 9.99957 16.0871Z" fill="#12B347" />
                    <path d="M15 18.6603V18.6557L13.0411 15.2646C12.1451 15.7843 11.1083 16.0868 10 16.0868V19.9998C11.8139 19.9998 13.5256 19.5114 15 18.6603Z" fill="#0F993E" />
                    <path d="M3.91305 10.0002C3.91305 8.89207 4.21547 7.85531 4.73504 6.95934L1.34418 5.00488C0.488359 6.47469 0 8.18164 0 10.0002C0 11.8188 0.488359 13.5258 1.34418 14.9956L4.73504 13.0411C4.21547 12.1452 3.91305 11.1084 3.91305 10.0002Z" fill="#FFD500" />
                    <path d="M9.99957 3.91305C11.4656 3.91305 12.8123 4.43398 13.8641 5.30051C14.1236 5.51426 14.5007 5.49883 14.7384 5.26113L16.5849 3.41465C16.8546 3.14496 16.8354 2.70352 16.5473 2.45359C14.785 0.924726 12.492 0 9.99957 0C6.30191 0 3.06934 2.01395 1.34375 5.00465L4.73461 6.9591C5.78871 5.14141 7.75137 3.91305 9.99957 3.91305Z" fill="#FF4B26" />
                    <path d="M13.8645 5.30051C14.124 5.51426 14.5012 5.49883 14.7389 5.26113L16.5854 3.41465C16.855 3.14496 16.8358 2.70352 16.5477 2.45359C14.7854 0.924688 12.4925 0 10 0V3.91305C11.466 3.91305 12.8127 4.43398 13.8645 5.30051Z" fill="#D93F21" />
                </g>
                <defs>
                    <clipPath id="clip0_842_3485">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
};

export default GoogleSignUp;
