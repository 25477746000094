import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './component/home/LoginPage';
import Dashboard from './component/Dashboard/Dashboard';
import UploadIndex from './component/jobDescription/UploadIndex';
import StartPage from './component/home/StartPage';
import { Box } from '@mui/material';
import LandingPage from './component/home/LandingPage';

function App() {
  return (
    <Box className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<StartPage />} />
          <Route path="/upload-job-description" element={<UploadIndex />} />
          <Route path="/conversation" element={<Dashboard />} />
          <Route path="/mock-interview" element={<Dashboard />} />
        </Routes>
      </Router>
    </Box>
  );
}

export default App;
