import { SvgIcon } from '@mui/material';
import React from 'react';

const LandingIcon2 = ({ width, height }) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
      }}
    >
      <svg
        width="136"
        height="122"
        viewBox="0 0 136 122"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_758_1494)">
          <path
            d="M92.0953 29.416H86.0346C81.9848 29.416 78.7017 32.699 78.7017 36.749V43.822L80.4764 44.8903H92.7995V30.7057L92.0953 29.416Z"
            fill="#EFC984"
          />
          <path
            d="M105.44 78.6839C105.44 74.6756 102.798 71.147 98.9521 70.0179L88.226 66.8689L88.2329 64.5713H83.4357L72.9499 67.6489C69.1913 68.7522 66.6094 72.2006 66.6094 76.1177V92.868H105.441L105.44 78.6839Z"
            fill="#4A80AA"
          />
          <path
            d="M110.327 67.6485L99.8447 64.5713H87.4372L87.4282 67.5915L97.9102 70.6687C101.668 71.7722 104.25 75.2204 104.25 79.1373V92.8678H116.666V76.1171C116.666 72.2004 114.085 68.752 110.327 67.6485Z"
            fill="#407093"
          />
          <path
            d="M97.5689 92.8677L93.7178 75.6963H89.5459L85.6948 92.8677H97.5689Z"
            fill="#DD636E"
          />
          <path
            d="M97.3948 66.6826V57.4258H85.877V66.6826C85.877 69.8632 91.6358 70.1459 91.6358 70.1459C91.6358 70.1459 97.3948 69.863 97.3948 66.6826Z"
            fill="#FFDDCE"
          />
          <path
            d="M92.5484 53.678C92.7281 52.8898 92.7995 52.0809 92.7995 51.2726V39.006C90.5796 39.1551 88.3376 38.8766 86.199 38.1701L86.1948 38.1687C84.6806 37.6684 83.1203 38.7962 83.1203 40.391V41.6049C83.1203 42.8293 82.1276 43.8219 80.9032 43.8219H78.7017V47.4474C78.7017 48.3738 79.4527 49.1248 80.3791 49.1248C80.6714 49.1248 80.9093 49.3538 80.9277 49.6455C81.2282 54.4117 84.6379 58.3338 89.1523 59.4037C90.8221 57.9198 92.0344 55.9324 92.5484 53.678Z"
            fill="#FFDDCE"
          />
          <path
            d="M101.384 33.6783H101.277C100.683 33.6783 100.201 33.1965 100.201 32.6022C100.201 30.8426 98.7743 29.416 97.0147 29.416H92.0957V44.8903H102.796L104.57 43.822V36.8644C104.57 35.1048 103.144 33.6783 101.384 33.6783Z"
            fill="#EABC6B"
          />
          <path
            d="M102.369 43.8224C101.144 43.8224 100.152 42.8298 100.152 41.6054V40.392C100.152 38.797 98.5912 37.6693 97.077 38.1698C95.4577 38.705 93.7793 38.9919 92.0958 39.0363V50.9006C92.0958 51.6951 92.0256 52.4904 91.8489 53.2651C91.3151 55.6069 90.0143 57.6557 88.2266 59.1372C89.2981 59.4963 90.444 59.6931 91.6365 59.6931C97.3335 59.6931 101.991 55.2536 102.345 49.6458C102.363 49.3541 102.601 49.1251 102.893 49.1251C103.82 49.1251 104.571 48.3741 104.571 47.4476V43.8222L102.369 43.8224Z"
            fill="#FFCBBE"
          />
          <path
            d="M83.6318 63.9572L81.9916 64.6935C81.0555 65.1138 80.8294 66.3411 81.5544 67.0675L86.0967 71.6168C86.6728 72.1777 87.5797 72.2123 88.1968 71.6968L91.7587 68.7216L85.6861 64.184C85.0934 63.7407 84.3069 63.654 83.6318 63.9572Z"
            fill="#365E7D"
          />
          <path
            d="M97.5821 64.2118L91.7588 68.7209L95.1065 71.5832C95.7197 72.1077 96.6308 72.0812 97.2129 71.5221L101.757 67.0964C102.5 66.3731 102.278 65.1284 101.331 64.7064L99.6593 63.9614C98.9735 63.6561 98.1755 63.7524 97.5821 64.2118Z"
            fill="#365E7D"
          />
          <path
            d="M105.442 107.963L105.44 89.0684H41.9668V109.907H104.203C104.901 109.535 105.388 108.804 105.442 107.963Z"
            fill="#DDB7A0"
          />
          <path
            d="M104.252 89.0684V107.75C104.175 108.941 103.21 109.907 102.018 109.907H112.573C114.833 109.907 116.666 108.074 116.666 105.813V89.0684H104.252Z"
            fill="#D6A889"
          />
          <path
            d="M48.6054 83.5296V74.5273H37.4043V83.5296C37.4043 86.6227 43.0048 86.8975 43.0048 86.8975C43.0048 86.8975 48.6054 86.6227 48.6054 83.5296Z"
            fill="#FFDDCE"
          />
          <path
            d="M43.269 77.6124C45.9961 76.6655 47.8242 74.0949 47.8233 71.2079C47.8227 69.6116 47.5896 68.0239 47.1314 66.4947C46.3778 63.9808 45.9944 61.3702 45.9936 58.7456V58.7391C45.9917 52.707 42.5056 47.4927 37.4401 44.9902C31.9661 47.1952 28.1018 52.5552 28.0998 58.8184V58.8247C28.0991 61.3759 27.7266 63.9134 26.9939 66.3571C26.5483 67.8436 26.3217 69.3869 26.3213 70.9386C26.3203 73.7448 28.0972 76.2434 30.7481 77.1639C33.249 78.0321 35.8193 78.6268 38.4149 78.9482C40.0532 78.6109 41.6748 78.1659 43.269 77.6124Z"
            fill="#407093"
          />
          <path
            d="M59.0197 66.3571C58.2872 63.9132 57.9146 61.3757 57.9138 58.8246V58.8183C57.9113 50.5873 51.2379 43.916 43.0069 43.916C40.7793 43.916 38.6677 44.4081 36.7695 45.284C41.8849 47.6446 45.4374 52.8149 45.4391 58.8183V58.8246C45.4399 61.3759 45.8123 63.9134 46.545 66.3571C46.9906 67.8433 47.2172 69.3866 47.2176 70.9384C47.2186 73.7446 45.4418 76.2434 42.7908 77.1637C40.8197 77.8479 38.8053 78.3621 36.7695 78.7066C42.934 79.7499 49.2967 79.2358 55.2655 77.1637C57.9165 76.2432 59.6933 73.7446 59.6923 70.9384C59.6918 69.3868 59.4651 67.8435 59.0197 66.3571Z"
            fill="#365E7D"
          />
          <path
            d="M61.2207 32.71V16.3404C61.2207 12.3511 57.7484 12.0041 55.3541 12.002H29.7779C27.3818 12.002 25.4395 13.9443 25.4395 16.3404V32.71C25.4395 35.106 27.3818 37.0484 29.7779 37.0484H56.0384L57.0984 36.9912C58.3925 36.9213 59.6214 36.3142 60.3864 35.2681C60.9111 34.5509 61.2207 33.6667 61.2207 32.71Z"
            fill="#CBE2FF"
          />
          <path
            d="M67.9319 12.002H55.354C57.75 12.002 59.6924 13.9443 59.6924 16.3404V32.71C59.6924 34.7973 58.2182 36.5399 56.2542 36.9542C56.1746 36.9711 56.1024 37.0044 56.0383 37.0484H60.0953C60.263 37.0484 60.4238 37.115 60.5425 37.2337L67.0365 43.7277C67.5186 44.2099 68.3432 43.8684 68.3432 43.1864V37.5694C68.3432 37.2756 68.5446 37.0149 68.8321 36.9542C70.7961 36.5399 72.2705 34.7975 72.2705 32.71V16.3404C72.2703 13.9443 70.3279 12.002 67.9319 12.002Z"
            fill="#BED8FB"
          />
          <path
            d="M55.7926 95.0415C55.7926 91.2323 53.6502 89.3931 49.627 88.5293L47.3537 88.0965C45.7189 87.7853 44.4591 86.5538 44.0621 85.0009C41.454 85.4473 38.5848 84.2159 37.4172 81.3071L37.404 81.2744L24.8325 83.7057C21.1774 84.7785 18.6665 88.1321 18.6665 91.9417V105.904C18.6665 108.168 20.5044 110.002 22.7685 109.998L54.8723 109.932L55.7719 109.242L55.7926 95.0415Z"
            fill="#E28086"
          />
          <path
            d="M65.4996 109.922C66.5223 109.914 67.3471 109.083 67.3471 108.06V91.9411C67.3471 88.1319 64.8366 84.7783 61.1815 83.7052L48.6052 81.3105C47.5966 83.8182 45.3235 85.0767 43.0444 85.0885C43.2724 86.877 44.623 88.3501 46.4335 88.6948L48.7068 89.1276C52.7298 89.9916 54.8724 91.8306 54.8724 95.6398V109.932L65.4996 109.922Z"
            fill="#DD636E"
          />
          <path
            d="M95.1066 71.584L91.7589 68.7217L88.197 71.6969C87.9675 71.8887 87.6977 72.0039 87.4199 72.0447L89.5461 75.6963H93.718L95.8204 71.9287C95.5641 71.8782 95.3175 71.7645 95.1066 71.584Z"
            fill="#E28086"
          />
        </g>
        <defs>
          <clipPath id="clip0_758_1494">
            <rect
              width="98"
              height="98"
              fill="white"
              transform="translate(18.6665 12)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default LandingIcon2;
