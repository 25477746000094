import { SvgIcon } from '@mui/material';
import React from 'react';

const LandingIcon3 = ({ width, height }) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
      }}
    >
      <svg
        width="136"
        height="122"
        viewBox="0 0 136 122"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100.032 13.947C93.9328 13.941 88.6153 17.2786 85.8033 22.2275L85.801 22.2268C84.6025 23.7072 82.758 23.7109 81.5557 22.2223L81.5549 22.2238C78.7526 17.2682 73.4419 13.9201 67.343 13.9141C58.3148 13.9051 50.989 21.2152 50.98 30.2419C50.977 33.0517 51.3441 36.3131 51.9998 38.8425H76.7802C80.2443 38.8425 83.078 41.677 83.078 45.141V69.9812L90.3634 58.1021C91.1858 56.739 92.8367 56.1192 94.353 56.6045C97.0118 57.4546 98.0518 59.7372 97.1336 62.3721L97.0925 62.4902C106.012 55.714 116.345 44.8935 116.36 30.3077C116.369 21.2817 109.059 13.9559 100.032 13.947Z"
          fill="#FF6B6B"
        />
        <path
          d="M69.3087 14.0352C68.6642 13.9574 68.0084 13.9148 67.343 13.9141C58.3148 13.9051 50.989 21.2152 50.98 30.2419C50.977 33.0517 51.3441 36.3131 51.9998 38.8425H55.9439C55.2881 36.3131 54.921 33.0517 54.924 30.2419C54.9322 21.8851 61.2135 15.0042 69.3087 14.0352Z"
          fill="#EE5253"
        />
        <path
          d="M87.022 63.5507V45.1412C87.022 41.6772 84.1876 38.8428 80.7243 38.8428H76.7803C80.2443 38.8428 83.078 41.6772 83.078 45.1412V69.9815L87.022 63.5507Z"
          fill="#EE5253"
        />
        <path
          d="M77.3715 38.251H25.2977C21.8337 38.251 19 41.0847 19 44.5487V75.7981C19 79.2621 21.8337 82.0958 25.2977 82.0958H30.918C31.7495 82.0958 32.5143 82.5474 32.9166 83.2741L38.2431 92.8975C38.7059 93.7327 39.9067 93.7327 40.3687 92.8975L45.8178 83.0528C46.1446 82.4621 46.7659 82.0958 47.4403 82.0958H52.061V75.4661C52.061 74.2847 53.018 73.3277 54.1986 73.3277H68.332C69.3743 73.3277 70.2184 74.1726 70.2184 75.2134V77.2104H73.8582C76.8736 77.1798 78.6493 75.8968 78.6493 75.6754C80.2934 74.0178 81.738 72.174 82.9537 70.1807L83.6692 68.9949V44.5487C83.6692 41.0847 80.8355 38.251 77.3715 38.251Z"
          fill="#54A0FF"
        />
        <path
          d="M22.944 75.7981V44.5487C22.944 41.0847 25.7777 38.251 29.2417 38.251H25.2977C21.8337 38.251 19 41.0847 19 44.5487V75.7988C19 79.2621 21.8337 82.0965 25.2977 82.0965H29.2417C25.7777 82.0965 22.944 79.2621 22.944 75.7981Z"
          fill="#338DEF"
        />
        <path
          d="M36.8605 83.274C36.4583 82.5473 35.6934 82.0957 34.862 82.0957H30.918C31.7494 82.0957 32.5143 82.5473 32.9165 83.274L38.243 92.8975C38.7051 93.7326 39.9066 93.7326 40.3687 92.8975L41.2778 91.2548L36.8605 83.274Z"
          fill="#338DEF"
        />
        <path
          d="M74.1627 75.2138C74.1627 74.1723 73.3185 73.3281 72.277 73.3281H68.333C69.3745 73.3281 70.2187 74.1723 70.2187 75.2138V77.2101H73.8584C73.9631 77.2093 74.0617 77.2034 74.1627 77.1996V75.2138Z"
          fill="#338DEF"
        />
        <path
          d="M37.0014 63.3219C35.2122 63.3219 33.7505 61.8631 33.7505 60.0709C33.7505 58.0163 35.6421 56.4813 37.6384 56.8851C39.0889 57.1595 40.2523 58.4717 40.2523 60.0709C40.2523 61.8579 38.8026 63.3219 37.0014 63.3219Z"
          fill="#338DEF"
        />
        <path
          d="M50.6508 63.3219C48.8616 63.3219 47.3999 61.8631 47.3999 60.0709C47.3999 58.0163 49.2923 56.4813 51.2878 56.8851C52.7383 57.1595 53.9017 58.4717 53.9017 60.0709C53.9025 61.8579 52.452 63.3219 50.6508 63.3219Z"
          fill="#338DEF"
        />
        <path
          d="M64.3012 63.3219C62.512 63.3219 61.0503 61.8631 61.0503 60.0709C61.0503 58.0163 62.9419 56.4813 64.9382 56.8851C66.3887 57.1595 67.5521 58.4717 67.5521 60.0709C67.5521 61.8579 66.1024 63.3219 64.3012 63.3219Z"
          fill="#338DEF"
        />
        <path
          d="M37.7274 60.0709C37.7274 59.0122 38.2306 58.0926 38.9902 57.5042C38.5962 57.1969 38.1349 56.9793 37.6384 56.8851C35.6421 56.4813 33.7505 58.0163 33.7505 60.0709C33.7505 61.8631 35.2122 63.3219 37.0014 63.3219C37.7536 63.3219 38.4414 63.0639 38.9917 62.637C38.2246 62.0418 37.7274 61.114 37.7274 60.0709Z"
          fill="#1C78D3"
        />
        <path
          d="M51.3778 60.0709C51.3778 59.0122 51.881 58.0926 52.6406 57.5042C52.2466 57.1969 51.7853 56.9793 51.2888 56.8851C49.2925 56.4813 47.4009 58.0163 47.4009 60.0709C47.4009 61.8631 48.8626 63.3219 50.6511 63.3219C51.4032 63.3219 52.0918 63.0639 52.6414 62.637C51.875 62.0418 51.3778 61.114 51.3778 60.0709Z"
          fill="#1C78D3"
        />
        <path
          d="M65.0272 60.0709C65.0272 59.0122 65.5304 58.0926 66.29 57.5042C65.896 57.1969 65.4347 56.9793 64.9382 56.8851C62.9419 56.4813 61.0503 58.0163 61.0503 60.0709C61.0503 61.8631 62.512 63.3219 64.3012 63.3219C65.0534 63.3219 65.742 63.0639 66.2915 62.637C65.5244 62.0418 65.0272 61.114 65.0272 60.0709Z"
          fill="#1C78D3"
        />
        <path
          d="M97.3135 76.816H112.98C115.109 76.816 116.921 78.4706 116.997 80.5978C117.077 82.8311 115.29 84.6659 113.075 84.6659H110.71C112.927 84.6659 114.712 86.5022 114.633 88.7355C114.557 90.8619 112.744 92.5166 110.616 92.5166H108.252C110.467 92.5166 112.254 94.3521 112.174 96.5854C112.097 98.7126 110.285 100.366 108.157 100.366H106.521C108.737 100.366 110.524 102.203 110.444 104.435C110.367 106.562 108.554 108.216 106.426 108.216H86.754C79.6839 108.216 80.2043 102.586 74.5212 102.586C72.815 102.586 71.8908 102.586 70.2183 102.586V76.816H73.858C76.8741 76.7846 78.6491 75.5023 78.6491 75.281C80.2933 73.6234 81.7378 71.7789 82.9543 69.7855L90.363 57.5101C91.1855 56.1471 92.8364 55.5272 94.3527 56.0125C97.0114 56.8619 98.4462 59.7359 97.5273 62.3715L94.0656 72.306C93.3246 74.5236 94.9755 76.816 97.3135 76.816Z"
          fill="#FFCDBF"
        />
        <path
          d="M106.426 105.292H86.754C79.6839 105.292 80.2043 99.6611 74.5212 99.6611C72.815 99.6611 71.8908 99.6611 70.2183 99.6611V102.587H74.5212C80.2043 102.587 79.6839 108.217 86.754 108.217H106.426C108.555 108.217 110.367 106.563 110.444 104.436C110.464 103.862 110.36 103.314 110.158 102.817C109.56 104.277 108.091 105.292 106.426 105.292Z"
          fill="#FFBEAB"
        />
        <path
          d="M97.9599 81.709C97.1434 81.709 96.481 82.3707 96.481 83.1879C96.481 84.0051 97.1427 84.6668 97.9599 84.6668H110.722C110.718 84.6668 110.715 84.6661 110.711 84.6661H113.075C114.908 84.6661 116.445 83.4085 116.876 81.709H97.9599Z"
          fill="#FFBEAB"
        />
        <path
          d="M97.9599 89.5596C97.1434 89.5596 96.481 90.2213 96.481 91.0385C96.481 91.855 97.1427 92.5174 97.9599 92.5174H108.264C108.26 92.5174 108.256 92.5167 108.252 92.5167H110.617C112.46 92.5167 114.065 91.274 114.511 89.5596H97.9599Z"
          fill="#FFBEAB"
        />
        <path
          d="M97.9599 97.4102C97.1434 97.4102 96.481 98.0726 96.481 98.8891C96.481 99.7063 97.1427 100.368 97.9599 100.368H106.533C106.529 100.368 106.525 100.368 106.522 100.368H108.158C110.001 100.368 111.606 99.1253 112.052 97.4102H97.9599Z"
          fill="#FFBEAB"
        />
        <path
          d="M68.727 72.9336H61.1836V106.262H68.727C69.7685 106.262 70.6134 105.417 70.6134 104.376V74.8192C70.6126 73.7785 69.7685 72.9336 68.727 72.9336Z"
          fill="#E4EAF8"
        />
        <path
          d="M68.727 102.317H61.1836V106.261H68.727C69.7685 106.261 70.6134 105.417 70.6134 104.376V100.432C70.6134 101.473 69.7685 102.317 68.727 102.317Z"
          fill="#CCD2DB"
        />
        <path
          d="M53.8046 72.9336C52.6233 72.9336 51.667 73.8906 51.667 75.0712V104.123C51.667 105.304 52.624 106.262 53.8046 106.262H61.1835V72.9336C60.7715 72.9336 54.2181 72.9336 53.8046 72.9336Z"
          fill="#576574"
        />
        <path
          d="M51.666 100.18V104.123C51.666 105.304 52.623 106.261 53.8044 106.261H61.1833V102.317C60.772 102.317 54.2179 102.317 53.8044 102.317C52.623 102.317 51.666 101.36 51.666 100.18Z"
          fill="#3E4B5A"
        />
      </svg>
    </SvgIcon>
  );
};

export default LandingIcon3;
