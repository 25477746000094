import {
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import React from 'react';
import BackIcon from '../assets/BackIcon';
import CrossIcon from '../assets/CrossIcon';
import { useNavigate } from 'react-router-dom';

const UploadResumeNext = ({
  activeStep,
  setActiveStep,
  jobTitle,
  setJobTitle,
  companyName,
  setCompanyName,
  jobDescription,
  setJobDescription,
  resume,
  setResume,
  aboutYou,
  setAboutYou,
  handleSubmit,
}) => {
  const breakPointLarge = window.innerWidth <= 850;
  const breakPointSmall = window.innerWidth <= 700;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: 'linear-gradient(to bottom, #A9A7F8, #606082)',
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: '40px',
          m: '40px 20px',
          borderRadius: '8px',
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '100%', // Allows the box to take up the full width initially
          width: breakPointLarge ? '100%' : '800px',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '40px',
            marginBottom: '32px',
          }}
        >
          Upload job description
        </Typography>

        <Box
          sx={{
            maxWidth: '100%', // Allows the box to take up the full width initially
            width: breakPointSmall ? '100%' : '600px',
            padding: '24px',
            backgroundColor: '#F7F7F7',
            borderRadius: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              //   alignItems: 'center',
              marginBottom: '32px',
            }}
          >
            <Box onClick={() => setActiveStep(0)}>
              <BackIcon width="36px" height="36px" />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '#505050', // Active step color
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                  }}
                >
                  1
                </Box>
                <Typography
                  sx={{
                    marginTop: '8px',
                    fontSize: '14px',
                    color: '#505050',
                    fontWeight: 400,
                  }}
                >
                  About the job
                </Typography>
              </Box>
              <Box sx={{ width: '80px' }} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '#606082', // Active step color
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                  }}
                >
                  2
                </Box>
                <Typography
                  sx={{
                    marginTop: '8px',
                    fontSize: '14px',
                    color: '#606082',
                    fontWeight: 600,
                  }}
                >
                  About you
                </Typography>
              </Box>
            </Box>
            <Box onClick={() => navigate('/dashboard')}>
              <CrossIcon width="36px" height="36px" />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                marginBottom: '14px',
              }}
            >
              Resume*
            </Typography>

            <TextField
              fullWidth
              placeholder="Copy and paste your resume here"
              variant="outlined"
              multiline
              rows={4}
              sx={{
                marginBottom: '24px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                  backgroundColor: '#fff',
                  border: '2px solid #D2D2D2',
                },
              }}
              value={resume}
              onChange={(e) => setResume(e.target.value)}
            />

            <Typography
              sx={{
                fontSize: '14px',
                marginBottom: '14px',
              }}
            >
              About you
            </Typography>

            <TextField
              fullWidth
              placeholder="Enter the job title you are interviewing for (Eg. Product Designer)"
              variant="outlined"
              multiline
              rows={4}
              sx={{
                marginBottom: '24px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                  backgroundColor: '#fff',
                  border: '2px solid #D2D2D2',
                },
              }}
              onChange={(e) => setAboutYou(e.target.value)}
              value={aboutYou}
            />

            <Button
              variant="contained"
              sx={{
                width: '100%',
                borderRadius: '30px',
                backgroundColor: '#606082',
                color: '#fff',
                padding: '12px 24px',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: '#505072',
                },
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadResumeNext;
