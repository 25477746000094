import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import LandingIcon1 from '../../assets/LandingIcon1';
import CircleIcon from '@mui/icons-material/Circle';

const Page2 = ({ handleLogin }) => {
  const [value, setValue] = React.useState('chat');
  return (
    <Box
      sx={{
        mt: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: '60px',
      }}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        gutterBottom
        sx={{
          color: '#505050',
          fontWeight: '500',
          fontSize: '32px',
          lineHeight: '40px',
          fontFamily: 'Montserrat',
          lineHeight: '40px',
          maxWidth:'800px',
        }}
      >
        Increase your chances of getting your dream job by practicing with Su
      </Typography>

      <Box
        onClick={(event, newValue) => {
          setValue(event.target.value);
        }}
        sx={{
          display: 'flex',
          gap: 2, // No spacing between buttons
          border: '1.5px solid #D6D6D6',
          borderRadius: '40px',
          p: '8px 8px',
        }}
      >
        <Button
          value="chat"
          sx={{
            border: 'none',
            background: value === 'chat' ? '#5C8AFF' : '#fff',
            '&:hover': {
              background: '#356EFF',
              color: '#fff',
            },
            color: value === 'chat' ? '#fff' : '#505050',
            p: '8px 16px',
            borderRadius: '20px',
          }}
        >
          Chat Session
        </Button>
        <Button
          value="resume"
          sx={{
            border: 'none',
            background: value === 'resume' ? '#5C8AFF' : '#fff',
            '&:hover': {
              background: '#356EFF',
              color: '#fff',
            },
            color: value === 'resume' ? '#fff' : '#505050',
            p: '8px 16px',
            borderRadius: '20px',
          }}
        >
          Resume Feedback
        </Button>
        <Button
          value="mock"
          sx={{
            border: 'none',
            background: value === 'mock' ? '#5C8AFF' : '#fff',
            '&:hover': {
              background: '#356EFF',
              color: '#fff',
            },
            color: value === 'mock' ? '#fff' : '#505050',
            p: '8px 16px',
            borderRadius: '20px',
          }}
        >
          Mock interview
        </Button>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '65%',
            height: 'auto',
          }}
        >
          <img
            src="landing2.png"
            alt="Su2"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '35%',
            maxWidth: '450px',
            py: '40px',
          }}
        >
          <Typography
            sx={{
              color: '#505050',
              fontWeight: '500',
              fontSize: '28px',
              lineHeight: '40px',
              fontFamily: 'Montserrat',
              textAlign: 'left',
              mb: '8px',
            }}
          >
            Chat sessions
          </Typography>
          <Typography
            sx={{
              color: '#505050',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
              fontFamily: 'Montserrat',
              textAlign: 'left',
              mb: '24px',
            }}
          >
            {' '}
            Su is capable of understanding the job needs and help you build your
            resume to increase your chances of getting interviews. HiSu makes
            use of{' '}
          </Typography>
          <Box sx={{ mb: '24px', textAlign: 'left' }}>
            <LandingIcon1 width="23px" height="26px" fill="#356EFF" />
          </Box>
          <Typography
            sx={{
              color: '#505050',
              fontWeight: '500',
              fontSize: '18px',
              lineHeight: '22px',
              fontFamily: 'Montserrat',
              textAlign: 'left',
              mb: '24px',
            }}
          >
            Share your job concerns
          </Typography>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <CircleIcon
              sx={{ fill: '#356EFF', height: '12px', width: '12px', mt: '4px' }}
            />
            <Typography
              sx={{
                color: '#505050',
                fontWeight: '500',
                fontSize: '18px',
                lineHeight: '22px',
                fontFamily: 'Montserrat',
                textAlign: 'left',
                mb: '24px',
              }}
            >
              Ask your doubts
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <CircleIcon
              sx={{ fill: '#356EFF', height: '12px', width: '12px', mt: '4px' }}
            />
            <Typography
              sx={{
                color: '#505050',
                fontWeight: '500',
                fontSize: '18px',
                lineHeight: '22px',
                fontFamily: 'Montserrat',
                textAlign: 'left',
                mb: '24px',
              }}
            >
              Improve your skills
            </Typography>
          </Box>

          <Button
            sx={{
              p: '8px 16px',
              background: '#356EFF',
              color: '#fff',
              width: 'fit-content',
              '&:hover': { background: '#356EFF' },
              '&.Mui-disabled': {
                background: '#b0b0c0', // Lighter gray when disabled
                color: '#e0e0e0', // Light gray for text when disabled
                cursor: 'not-allowed', // Change cursor to indicate disabled state
              },
            }}
            onClick={handleLogin}
          >
            Get started for free
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Page2;
