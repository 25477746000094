import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import ChatWithSu from '../../assets/chatWithSu';
import PracticeInterview from '../../assets/PracticeInterview';

const Page7 = ({ handleLogin, page }) => {
  return (
    <Box
      sx={{
        mt: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        p: '60px',
        background: '#FAF9FF',
        mx: '16px',
      }}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        gutterBottom
        sx={{
          color: '#6135FF',
          fontWeight: '600',
          fontSize: '32px',
          lineHeight: '40px',
          fontFamily: 'Montserrat',
          textAlign: 'center',
          mb: '8px',
        }}
      >
        {page === 'Chat'
          ? 'See how you can have conversation with Su'
          : page === 'Resume'
          ? 'See how you can get resume feedback from Su'
          : 'See how you can practice interviews with Su'}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        gutterBottom
        sx={{
          color: '#313131',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '20px',
          fontFamily: 'Montserrat',
          textAlign: 'center',
          maxWidth: '800px',
        }}
      >
        This feature is designed to provide real-time conversational guidance
        for job seekers. Su engages with users in natural language and offers
        tailored insights into interview preparation, career advice, and more.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          gap: '16px',
          my: '16px',
        }}
      >
        <Box
          sx={{
            background: '#fff',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            alignItems: 'center',
            maxWidth: '300px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            p: '24px',
          }}
        >
          <ChatWithSu width="300px" height="255px" />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '22px',
                textAlign: 'left',
              }}
            >
              {page === 'Chat'
                ? ' Toggle to the “Conversation” mode from the drop down'
                : page === 'Resume'
                ? 'Toggle to the “Resume feedback” mode from the drop down'
                : 'Toggle to the “Mock interview” mode from the drop down'}
            </Typography>
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'left',
              }}
            >
              The current interaction mode with Su is displayed on the top right
              of the corner. Click on it and select “Conversation” option from
              the drop down.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            background: '#fff',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            alignItems: 'center',
            maxWidth: '300px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            p: '24px',
          }}
        >
          <img
            src={'./Su.jpg'}
            alt="Emily"
            style={{
              width: '300px',
              height: '255px',
              backgroundColor: '#FFFFFF',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '22px',
                textAlign: 'left',
              }}
            >
              {page === 'Chat'
                ? 'Begin the conversation'
                : page === 'Resume'
                ? 'Share your Resume'
                : 'Share the job description'}
            </Typography>
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'left',
              }}
            >
              {page === 'Chat'
                ? 'Begin the conversation: You can ask Su questions about interviews, job roles, or any career-related doubts you have.'
                : page === 'Resume'
                ? 'Copy and paste your resume into the dialogue box and click submit.'
                : 'Provide the job description of the role they are preparing for, along with an “About Me” section that gives Su context about their career experience and aspirations.'}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            background: '#fff',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            alignItems: 'center',
            maxWidth: '300px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            p: '24px',
          }}
        >
          <PracticeInterview width="300px" height="255px" />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '22px',
                textAlign: 'left',
              }}
            >
              {page === 'Chat'
                ? 'Receive real-time responses'
                : page === 'Resume'
                ? 'Receive feedback'
                : 'Practice interviews'}
            </Typography>
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'left',
                mb: '16px',
              }}
            >
              {page === 'Chat'
                ? 'Su provides personalized advice based on your queries.'
                : page === 'Resume'
                ? 'Su will highlight where changes need to be made and how to optimize your resume.'
                : 'Su will ask questions based on the job description and the information you provided. Get feedback on your responses, body language, tone, and areas for improvement.'}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Button
        sx={{
          p: '8px 16px',
          background: '#356EFF',
          color: '#fff',
          width: 'fit-content',
          '&:hover': { background: '#356EFF' },
          '&.Mui-disabled': {
            background: '#b0b0c0', // Lighter gray when disabled
            color: '#e0e0e0', // Light gray for text when disabled
            cursor: 'not-allowed', // Change cursor to indicate disabled state
          },
          my: '20px',
        }}
        onClick={handleLogin}
      >
        Get started for free
      </Button>
    </Box>
  );
};

export default Page7;
