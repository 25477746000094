import React, { useEffect } from 'react';
import { Box, Typography, Button, Card, CardContent, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import HiSu from '../assets/HiSu';
import ChatWithSu from '../assets/chatWithSu';
import PracticeInterview from '../assets/PracticeInterview';

const StartPage = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/');
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);
  return (
    <Box sx={{ maxHeight: '100vh', maxWidth: '100vw' }}>
      <Box
        sx={{
          padding: '12px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            background: '#F9FBFF',
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: '24px',
          }}
        >
          <div onClick={handleRedirect} style={{ cursor: 'pointer' }}>
            <HiSu height="32px" width="78px" />
          </div>
        </Box>
      </Box>

      <Box sx={{ pr: '12px', pl: '12px' }}>
        <Box
          sx={{
            background: '#FAF9FF',
            height: '100vh',
            alignItems: 'center',
            borderRadius: '16px',
          }}
        >
          <Box>
            <Typography sx={{ fontSize: '32px', textAlign: 'center', pt: 7.5, fontFamily: 'Montserrat', fontWeight: '600', color: '#6135FF' }}>
              What would you like to start with?
            </Typography>
            <Typography sx={{ textAlign: 'center', pt: 1, fontFamily: 'Montserrat', fontWeight: '400', fontSize: '16px' }}>
              You can select the type of conversation you want to have with Su.
            </Typography>
          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            p: 3,
            gap: 2,
          }}>

            <Card sx={{
              maxWidth: '400px',
              textAlign: 'center',
              borderRadius: '16px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
              <CardContent>
                <Typography sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  fontSize: '18px',
                }}
                  gutterBottom
                >
                  Have a chat with Su
                </Typography>
                <Typography sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  pb: 3,
                }}>
                  A conversation with Su about career, job, and interviews.
                </Typography>

                <ChatWithSu width="350px" height="255px" />

                <Button
                  variant="contained"
                  sx={{
                    marginTop: '24px',
                    width: 'fit-content',
                    p: '8px 16px',
                    borderRadius: '8px',
                    backgroundColor: '#007BFF',
                  }}
                  onClick={() => window.open('/conversation')}
                >
                  Get started
                </Button>
              </CardContent>

            </Card>


            <Card
              sx={{
                maxWidth: '400px',
                textAlign: 'center',
                borderRadius: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CardContent>
                <Typography sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  fontSize: '18px',
                }}
                  gutterBottom
                >
                  Resume feedback
                </Typography>
                <Typography sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  pb: 3,
                }}
                >
                  Share your resume and job description of your targeted job to get responses specific to a job.
                </Typography>

                {/* Placeholder for an image */}
                <Box sx={{
                  width: 'fit-content',
                  height: '100%',
                  backgroundColor: '#FAFAFA',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}>
                  <img src={'./Su.jpg'} alt="Emily" style={{ width: '100%', height: '100%', backgroundColor: '#FFFFFF' }} />
                </Box>

                <Button
                  variant="contained"
                  sx={{
                    marginTop: '24px',
                    width: 'fit-content',
                    p: '8px 16px',
                    borderRadius: '8px',
                    backgroundColor: '#007BFF',
                    '&:hover': {
                      cursor: 'not-allowed',
                    },
                  }}
                  disabled
                >
                  Get started
                </Button>
              </CardContent>
            </Card>


            <Card
              sx={{
                maxWidth: '400px',
                textAlign: 'center',
                borderRadius: '16px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CardContent>
                <Typography sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  fontSize: '18px',
                }}
                  gutterBottom
                >
                  Resume feedback
                </Typography>
                <Typography sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  pb: 3,
                }}
                >
                  Share your resume and job description of your targeted job to get responses specific to a job.
                </Typography>

                <PracticeInterview width="350px" height="255px" />

                <Button
                  variant="contained"
                  sx={{
                    marginTop: '24px',
                    width: 'fit-content',
                    p: '8px 16px',
                    borderRadius: '8px',
                    backgroundColor: '#007BFF',
                  }}
                  disabled
                >
                  Get started
                </Button>
              </CardContent>
            </Card>

          </Box>

        </Box>
      </Box>
    </Box>
  );
};

export default StartPage;
