import { SvgIcon } from '@mui/material';
import React from 'react';

const LinkedIn = ({ width, height }) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
      }}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="white" />
        <g clip-path="url(#clip0_874_10678)">
          <path
            d="M17.6437 19.5312H13.125C12.7366 19.5312 12.4219 19.8462 12.4219 20.2344V35.297C12.4219 35.6854 12.7366 36.0001 13.125 36.0001H17.6437C18.032 36.0001 18.3468 35.6854 18.3468 35.297V20.2344C18.3468 19.8462 18.032 19.5312 17.6437 19.5312ZM16.9406 34.5939H13.8281V20.9375H16.9406V34.5939Z"
            fill="#356EFF"
          />
          <path
            d="M15.3845 11.5068C13.7509 11.5068 12.4219 12.8358 12.4219 14.4691C12.4219 16.1028 13.7509 17.4316 15.3845 17.4316C17.018 17.4316 18.3468 16.1026 18.3468 14.4691C18.3468 12.8358 17.018 11.5068 15.3845 11.5068ZM15.3845 16.0253C14.5263 16.0253 13.8281 15.3273 13.8281 14.4691C13.8281 13.6111 14.5263 12.9131 15.3845 12.9131C16.2426 12.9131 16.9406 13.6111 16.9406 14.4691C16.9406 15.3273 16.2426 16.0253 15.3845 16.0253Z"
            fill="#356EFF"
          />
          <path
            d="M29.3348 19.4316C28.266 19.4316 27.2126 19.6893 26.2692 20.1712C26.2372 19.8125 25.936 19.5311 25.5688 19.5311H21.0498C20.6616 19.5311 20.3467 19.846 20.3467 20.2342V35.2968C20.3467 35.6852 20.6616 35.9999 21.0498 35.9999H25.5688C25.9572 35.9999 26.272 35.6852 26.272 35.2968V27.0124C26.272 25.9467 27.1392 25.0797 28.2048 25.0797C29.2705 25.0797 30.1373 25.9467 30.1373 27.0124V35.2968C30.1373 35.6852 30.4523 35.9999 30.8405 35.9999H35.3593C35.7477 35.9999 36.0624 35.6852 36.0624 35.2968V26.1593C36.0624 22.4496 33.0445 19.4316 29.3348 19.4316ZM34.6562 34.5937H31.5438V27.0124C31.5438 25.1713 30.046 23.6735 28.205 23.6735C26.3637 23.6735 24.8657 25.1713 24.8657 27.0124V34.5937H21.7531V20.9373H24.8657V21.4568C24.8657 21.7272 25.0208 21.9737 25.2647 22.0907C25.5084 22.2077 25.7977 22.1744 26.0089 22.0054C26.961 21.2416 28.1113 20.8379 29.3348 20.8379C32.269 20.8379 34.6562 23.225 34.6562 26.1593V34.5937Z"
            fill="#356EFF"
          />
        </g>
        <defs>
          <clipPath id="clip0_874_10678">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(12 12)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default LinkedIn;
