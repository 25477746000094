import React, { useEffect } from 'react';
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Select,
  FormControl,
  Button,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Mic, MicOff } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { getAuth, signOut } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardIcon from '../assets/KeyboardIcon';
import BurgerIcon from '../assets/BurgerIcon';
import NewConversationIcon from '../assets/NewConversationIcon';
import ConversationIcon from '../assets/ConversationIcon';
import MockInterviewIcon from '../assets/MockInterviewIcon';

function Header({
  isDrawerOpen,
  toggleDrawer,
  isChatOpen,
  toggleChat,
  user,
  saveAndDisconnect,
  isMicOn,
  setIsMicOn,
  scene,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const auth = getAuth();
  const navigate = useNavigate();

  const [value, setValue] = React.useState('conversation');

  const location = useLocation();
  const path = location.pathname.split('/').pop();
  const page = path === 'conversation' ? 'conversation' : 'mockInterview';

  useEffect(() => {
    if (page === 'mockInterview') {
      setValue('mockInterview');
    } else {
      setValue('conversation');
    }
  }, [page]);

  const toggleMic = (state) => {
    if (scene) {
      setIsMicOn(state);
      scene
        .setMediaDeviceActive({ microphone: state })
        .then(() => {
          // console.log('Microphone active: ' + state);
        })
        .catch((error) => console.error('Microphone update failed:', error));
    } else {
      setIsMicOn(false);
    }
  };

  const handleMicClick = () => {
    toggleMic(!isMicOn);
  };

  const handleConversationChange = (event) => {
    if (value === event.target.value) return;
    saveAndDisconnect();
    if (event.target.value === 'conversation') {
      navigate('/conversation');
    } else {
      navigate('/mock-interview');
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirect to the home or login page after logging out
    } catch (error) {
      console.error('Error logging out: ', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: '8px 0',
        alignItems: 'center',
        gap: '16px',
        background: '#fff',
      }}
    >
      <Box
        sx={{
          gap: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!isDrawerOpen && (
          <BurgerIcon height="36px" width="36px" toggleDrawer={toggleDrawer} />
        )}
        {!isDrawerOpen && <NewConversationIcon height="36px" width="36px" />}
        {/* <UploadResumeIcon width="36px" height="36px" /> */}
        <Tooltip title={'See conversation'}>
          <KeyboardIcon
            width="36px"
            height="36px"
            toggleChat={toggleChat}
            isChatOpen={isChatOpen}
          />
        </Tooltip>
        <Typography
          sx={{
            color: '#505050',
            fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '20px',
          }}
        >
          HiSu v1.0
        </Typography>
        <Tooltip title={'Mute Microphone'}>
          <IconButton
            onClick={handleMicClick}
            color={isMicOn ? 'secondary' : 'primary'}
          >
            {isMicOn ? <Mic /> : <MicOff />}
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <Button
          sx={{
            p: '8px 16px',
            background: '#356EFF',
            color: '#fff',
            '&:hover': {
              background: '#356EFF',
              cursor: !scene ? 'not-allowed' : 'pointer',
            },
            '&.Mui-disabled': {
              background: '#7098FF', // Lighter gray when disabled
              color: '#e0e0e0', // Light gray for text when disabled
              cursor: 'not-allowed', // Change cursor to indicate disabled state
              pointerEvents: 'auto'
            },
          }}
          onClick={saveAndDisconnect}
          disabled={!scene}
        >
          Disconnect
        </Button>
        <FormControl variant="outlined">
          <Select
            value={value}
            onChange={handleConversationChange}
            label=""
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '50px',
              padding: '0 8px',
              border: '2px solid rgba(179, 176, 255, 1)',
              borderRadius: '40px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  display: 'flex',
                  alignItems: 'center',
                },
              },
            }}
          >
            <MenuItem
              value="conversation"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center ',
              }}
            >
              <ConversationIcon
                width="20px"
                height="20px"
                fill="rgba(80, 80, 80, 1)"
                mr="10px"
              />{' '}
              Conversations
            </MenuItem>
            <MenuItem value="mockInterview">
              <MockInterviewIcon
                width="20px"
                height="20px"
                fill="rgba(80, 80, 80, 1)"
                mr="10px"
              />{' '}
              Mock Interview
            </MenuItem>
          </Select>
        </FormControl>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
        <Avatar
          alt={auth.currentUser?.displayName}
          src={auth.currentUser?.photoURL}
          sx={{ marginLeft: '10px', width: '32px', height: '32px' }}
          onClick={handleClick}
        >
          <AccountCircleIcon style={{ width: '100%', height: '100%' }} />
        </Avatar>
      </Box>
    </Box>
  );
}

export default Header;
