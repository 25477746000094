import { Box, Button } from '@mui/material';
import React from 'react';
import HiSu from '../../assets/HiSu';

const Header = ({ handleLogin, page, setPage }) => {
  const handleAbout = () => {
    setPage('About');
  }
  return (
    <Box
      sx={{
        display: 'flex',
        p: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#fff',
        borderRadius: '16px',
      }}
    >
      <div onClick={handleAbout} style={{ cursor: 'pointer' }}>
        <HiSu height="32px" width="78px" />
      </div>
      <Box
        onClick={(event, newValue) => {
          setPage(event.target.value);
        }}
        sx={{
          display: 'flex',
          gap: 2, // No spacing between buttons
        }}
      >
        <Button
          value="Chat"
          sx={{
            border: 'none',
            background: page === 'Chat' ? '#5C8AFF' : '#fff',
            '&:hover': {
              background: '#356EFF',
              color: '#fff',
            },
            color: page === 'Chat' ? '#fff' : '#505050',
            p: '8px 16px',
          }}
        >
          Chat with Su
        </Button>
        <Button
          value="Resume"
          sx={{
            border: 'none',
            background: page === 'Resume' ? '#5C8AFF' : '#fff',
            '&:hover': {
              background: '#356EFF',
              color: '#fff',
            },
            color: page === 'Resume' ? '#fff' : '#505050',
            p: '8px 16px',
          }}
        >
          Resume Feedback
        </Button>
        <Button
          value="Mock"
          sx={{
            border: 'none',
            background: page === 'Mock' ? '#5C8AFF' : '#fff',
            '&:hover': {
              background: '#356EFF',
              color: '#fff',
            },
            color: page === 'Mock' ? '#fff' : '#505050',
            p: '8px 16px',
          }}
        >
          Mock interview
        </Button>
      </Box>
      <Button
        sx={{
          p: '8px 16px',
          background: '#356EFF',
          color: '#fff',
          '&:hover': { background: '#356EFF' },
          '&.Mui-disabled': {
            background: '#b0b0c0', // Lighter gray when disabled
            color: '#e0e0e0', // Light gray for text when disabled
            cursor: 'not-allowed', // Change cursor to indicate disabled state
          },
        }}
        onClick={handleLogin}
      >
        Get started for free
      </Button>
    </Box>
  );
};

export default Header;
