import { SvgIcon } from '@mui/material';
import React from 'react';

const NewConversationIcon = ({ width, height, fill = '#7098FF' }) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
        fill,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.16699 8.66669C5.16699 6.7337 6.734 5.16669 8.66699 5.16669H27.3337C29.2667 5.16669 30.8337 6.7337 30.8337 8.66669V18.2285C30.8337 18.7217 30.2426 19.0101 29.8091 18.7746C28.8999 18.2806 27.8578 18 26.7503 18C23.4032 18 20.6545 20.5628 20.3598 23.8334C20.3468 23.9779 20.3353 24.2711 20.3353 24.2711C20.3353 24.2989 20.3354 24.3295 20.3358 24.3623C20.3388 24.7051 20.0644 25 19.7215 25H18.0003L12.6502 30.3501C12.3408 30.6595 11.9212 30.8334 11.4836 30.8334C10.5723 30.8334 9.83366 30.0946 9.83366 29.1835V25H8.66699C6.734 25 5.16699 23.4331 5.16699 21.5V8.66669ZM26.7503 20.3334C27.3947 20.3334 27.917 20.8557 27.917 21.5V23.25H29.667C30.3113 23.25 30.8337 23.7723 30.8337 24.4167C30.8337 25.061 30.3113 25.5834 29.667 25.5834H27.917V27.3334C27.917 27.9777 27.3947 28.5 26.7503 28.5C26.106 28.5 25.5837 27.9777 25.5837 27.3334V25.5834H23.8337C23.1893 25.5834 22.667 25.061 22.667 24.4167C22.667 23.7723 23.1893 23.25 23.8337 23.25H25.5837V21.5C25.5837 20.8557 26.106 20.3334 26.7503 20.3334Z"
          fill="#7098FF"
        />
      </svg>
    </SvgIcon>
  );
};

export default NewConversationIcon;
