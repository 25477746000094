import React, { useState } from 'react';
import { Box } from '@mui/material';
import Page1 from './LandingPageComponents/Page1';
import Page2 from './LandingPageComponents/Page2';
import Page3 from './LandingPageComponents/Page3';
import Page4 from './LandingPageComponents/Page4';
import Page5 from './LandingPageComponents/Page5';
import Page6 from './LandingPageComponents/Page6';
import Page7 from './LandingPageComponents/Page7';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import Header from './LandingPageComponents/Header';

function LandingPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState('About');
  // page: About, Mock, Resume, Chat

  const handleLogin = () => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/dashboard');
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  };
  return (
    <Box sx={{ maxWidth: '100vw' }}>
      <Box
        sx={{
          px: '16px',
          width: '98%',
          position: 'fixed',
          background: '#fff',
          top: 0,
          zIndex: 50,
          boxShadow: 'rgba(17, 12, 46, 0.06) 0px 14px 20px 0px',
        }}
      >
        <Header handleLogin={handleLogin} page={page} setPage={setPage} />
      </Box>
      {page === 'About' && <Page1 handleLogin={handleLogin} />}
      {page !== 'About' && <Page5 handleLogin={handleLogin} page={page} />}

      {page === 'About' && <Page2 handleLogin={handleLogin} />}
      {page !== 'About' && <Page7 handleLogin={handleLogin} page={page} />}

      {page === 'About' && <Page3 handleLogin={handleLogin} />}

      <Page4 />

      {page !== 'About' && <Page2 handleLogin={handleLogin} />}

      {/* Footer Section */}
      <Page6 handleLogin={handleLogin} />
    </Box>
  );
}

export default LandingPage;
