import { SvgIcon } from '@mui/material';
import React from 'react';

const BurgerIcon = ({ width, height, fill = '#7098FF', toggleDrawer }) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
        fill,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={toggleDrawer}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_223_642)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0696 4.875H14.0625V31.125H12.0752C8.58075 31.125 5.55886 28.5371 5.22736 25.0097C4.75881 20.0241 4.75717 15.9502 5.22472 10.9842C5.55663 7.45888 8.57813 4.875 12.0696 4.875ZM8.375 11C7.89175 11 7.5 11.3917 7.5 11.875C7.5 12.3583 7.89175 12.75 8.375 12.75H11C11.4833 12.75 11.875 12.3583 11.875 11.875C11.875 11.3917 11.4833 11 11 11H8.375ZM7.5 15.375C7.5 14.8917 7.89175 14.5 8.375 14.5H11C11.4833 14.5 11.875 14.8917 11.875 15.375C11.875 15.8583 11.4833 16.25 11 16.25H8.375C7.89175 16.25 7.5 15.8583 7.5 15.375ZM8.375 18C7.89175 18 7.5 18.3917 7.5 18.875C7.5 19.3583 7.89175 19.75 8.375 19.75H11C11.4833 19.75 11.875 19.3583 11.875 18.875C11.875 18.3917 11.4833 18 11 18H8.375Z"
            fill="#7098FF"
          />
          <path
            d="M23.9113 31.125H15.8125V4.875H23.9199C27.3935 4.875 30.4082 7.43335 30.7557 10.9412C31.2493 15.9232 31.2483 20.0156 30.7513 25.0493C30.4046 28.5603 27.3895 31.125 23.9113 31.125Z"
            fill="#7098FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_223_642">
            <rect
              width="28"
              height="28"
              fill="white"
              transform="translate(4 4)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default BurgerIcon;
