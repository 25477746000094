import { SvgIcon } from '@mui/material';
import React from 'react';

const CheckIcon = ({ width, height, props, fill = '' }) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
        fill,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      {...props}
    >
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_123_276)">
          <path
            d="M12.4999 1.07141H4.49986C2.60631 1.07141 1.07129 2.60643 1.07129 4.49998V12.5C1.07129 14.3935 2.60631 15.9286 4.49986 15.9286H12.4999C14.3934 15.9286 15.9284 14.3935 15.9284 12.5V4.49998C15.9284 2.60643 14.3934 1.07141 12.4999 1.07141Z"
            stroke="#505050"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.07129 9.64284L7.41415 11.5171C7.47707 11.5689 7.55074 11.6061 7.62982 11.6259C7.7089 11.6456 7.79139 11.6475 7.87129 11.6314C7.95196 11.6163 8.02842 11.5839 8.09547 11.5366C8.16252 11.4893 8.21859 11.4281 8.25986 11.3571L11.9284 5.07141"
            stroke="#505050"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_123_276">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default CheckIcon;
