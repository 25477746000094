import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import CheckIcon from '../../assets/CheckIcon';
import LandingIcon2 from '../../assets/LandingIcon2';
import LandingIcon3 from '../../assets/LandingIcon3';
import LandingIcon4 from '../../assets/LandingIcon4';

const Page3 = ({ handleLogin }) => {
  return (
    <Box
      sx={{
        mt: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        p: '60px',
        background: '#FAF9FF',
        mx: '16px',
      }}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        gutterBottom
        sx={{
          color: '#6135FF',
          fontWeight: '500',
          fontSize: '32px',
          lineHeight: '40px',
          fontFamily: 'Montserrat',
          textAlign: 'center',
          mb: '8px',
        }}
      >
        Isn't an interview just a conversation?
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        gutterBottom
        sx={{
          color: '#313131',
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '22px',
          fontFamily: 'Montserrat',
          textAlign: 'center',
        }}
      >
        Su will help you to make interesting conversations with your
        interviewers.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '16px',
          my: '16px',
        }}
      >
        <Box
          sx={{
            background: '#fff',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            alignItems: 'center',
            maxWidth: '350px',
            p: '20px 30px',
          }}
        >
          <LandingIcon2 width="135px" height="122px" />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '30px',
                textAlign: 'center',
              }}
            >
              Personalized Mock Interviews
            </Typography>
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'center',
              }}
            >
              Get tailored practice sessions that mirror your job description
              and career goals.
            </Typography>
            <Typography
              sx={{
                color: '#356EFF',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'center',
              }}
            >
              Learn more
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            background: '#fff',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            alignItems: 'center',
            maxWidth: '350px',
            p: '20px 30px',
          }}
        >
          <LandingIcon3 width="135px" height="122px" />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '30px',
                textAlign: 'center',
              }}
            >
              Instant Feedback and Growth Tips
            </Typography>
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'center',
              }}
            >
              Receive real-time insights and actionable advice to improve your
              responses.
            </Typography>
            <Typography
              sx={{
                color: '#356EFF',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'center',
              }}
            >
              Learn more
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            background: '#fff',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            alignItems: 'center',
            maxWidth: '350px',
            p: '20px 30px',
          }}
        >
          <LandingIcon4 width="135px" height="122px" />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '30px',
                textAlign: 'center',
              }}
            >
              Cultural Fit
            </Typography>
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '30px',
                textAlign: 'center',
                mb: '16px',
              }}
            >
              Coaching
            </Typography>
            <Typography
              sx={{
                color: '#505050',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'center',
                mb: '16px',
              }}
            >
              Learn how to align with company culture and stand out as the
              perfect candidate.
            </Typography>
            <Typography
              sx={{
                color: '#356EFF',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'center',
              }}
            >
              Learn more
            </Typography>
          </Box>
        </Box>
      </Box>

      <Button
        sx={{
          p: '8px 16px',
          background: '#356EFF',
          color: '#fff',
          width: 'fit-content',
          '&:hover': { background: '#356EFF' },
          '&.Mui-disabled': {
            background: '#b0b0c0', // Lighter gray when disabled
            color: '#e0e0e0', // Light gray for text when disabled
            cursor: 'not-allowed', // Change cursor to indicate disabled state
          },
          mb: '20px',
        }}
        onClick={handleLogin}
      >
        Get started for free
      </Button>
      <Box>
        <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
          <Typography
            variant="body2"
            color="textSecondary"
            gutterBottom
            sx={{
              color: '#505050',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '20px',
              fontFamily: 'Montserrat',
              lineHeight: '40px',
            }}
          >
            <CheckIcon height="16px" width="16px" fill="#fff" /> Unlimited
            conversations
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            gutterBottom
            sx={{
              color: '#505050',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '20px',
              fontFamily: 'Montserrat',
              lineHeight: '40px',
            }}
          >
            <CheckIcon height="16px" width="16px" fill="#fff" /> free resume
            analysis
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            gutterBottom
            sx={{
              color: '#505050',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '20px',
              fontFamily: 'Montserrat',
              lineHeight: '40px',
            }}
          >
            <CheckIcon height="16px" width="16px" fill="#fff" /> 3 free job
            description analysis
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Page3;
