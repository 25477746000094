import React, { useEffect } from 'react';
import { auth, db } from './../firebase';
import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HiSu from '../assets/HiSu';
import { doc, getDoc, setDoc, Timestamp, updateDoc } from '@firebase/firestore';
import GoogleSignUp from '../assets/GoogleSignUp';

function LoginPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/dashboard');
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const data = await signInWithPopup(auth, provider);
      const userId = data.user.uid;
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        // User exists, update lastLogin field
        const userData = userSnap.data();

        // Check if `todaysTimeLeft` and `weekTimeLeft` are present
        if (!userData.todaysTimeLeft || !userData.weekTimeLeft) {
          await updateDoc(userRef, {
            todaysTimeLeft: userData.todaysTimeLeft || 300,  // Set to 5 if not present
            weekTimeLeft: userData.weekTimeLeft || 900,    // Set to 15 if not present
          });
        }

        await updateDoc(userRef, {
          lastLogin: Timestamp.now(),
        });
      } else {
        // User does not exist, create new user with default values
        await setDoc(userRef, {
          email: data.user.email,
          name: data.user.displayName,
          createdAt: Timestamp.now(),
          lastLogin: Timestamp.now(),
          userId: userId,
          todaysTimeLeft: 300,   // Initialize with default value
          weekTimeLeft: 900,    // Initialize with default value
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {/* Navbar remains the same */}
      <Box
        sx={{
          width: '96%',
          padding: '24px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            background: '#F3F2FF',
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '95%',
            padding: '24px',
          }}
        >
          <HiSu height="32px" width="78px" />
        </Box>
      </Box>

      {/* New two-column layout */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          padding: '24px',
          gap: '20px',
        }}
      >
        {/* Left column (60% width) */}
        <Box
          sx={{
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              maxWidth: '900px',
              width: '100%',
              padding: 4,
              textAlign: 'center',
              px: '40px',
              pt: '80px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h5"
                component="h1"
                gutterBottom
                sx={{
                  color: '#505050',
                  fontWeight: '600',
                  fontFamily: 'Montserrat',
                  fontSize: '32px',
                  lineHeight: '40px',
                }}
              >
                Start your free trial
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                sx={{
                  color: '#505050',
                  fontWeight: '600',
                  fontSize: '18px',
                  lineHeight: '40px',
                  fontFamily: 'Montserrat',
                }}
              >
                No credit card required
              </Typography>
            </Box>

            <Button
              variant="outlined"
              startIcon={<GoogleSignUp />}
              onClick={handleGoogleSignIn}
              fullWidth
              sx={{
                marginTop: 2,
                marginBottom: 2,
                padding: '16px 32px',
                borderRadius: '8px',
                border: '2px solid #ADADAD',
                height: '52px',
                width: '300px',
                color: '#505050',
                fontWeight: '600',
                fontFamily: 'Montserrat',
                textTransform: 'none',
              }}
            >
              Continue with Google
            </Button>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '28px',
                alignItems: 'center',
                maxWidth: '600px',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginTop: '12px', fontFamily: 'Montserrat', fontWeight: '500' }}
              >
                You agree to our <a href="/terms">Terms of Use</a> and{' '}
                <a href="/privacy">Privacy Policy</a>
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Right column (40% width) with image */}
        <Box
          sx={{
            width: '40%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={'./LoginImage.png'}
            alt="Right column image"
            style={{ width: '100%', borderRadius: '16px' }}
          />
        </Box>
      </Box>
    </Box>
  );

}

export default LoginPage;
