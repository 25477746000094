import React, { useEffect, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import CheckIcon from '../../assets/CheckIcon';

const Page5 = ({ handleLogin, page }) => {
  const videoRef = useRef(null);
  const boxRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (videoRef.current) {
            if (entry.isIntersecting) {
              videoRef.current.play();
            } else {
              videoRef.current.pause();
            }
          }
        });
      },
      {
        threshold: 0.5, // Adjust this threshold as needed
      }
    );

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);


  return (
    <Box
      sx={{
        background: '#fff',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '32px',
        py: '60px',
        mt: '30px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Typography
            sx={{
              color: '#505050',
              fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
              fontSize: '48px',
              fontWeight: 500,
              lineHeight: '60px',
              textAlign: 'center',
              maxWidth: '800px'
            }}
          >
            {page === 'Chat'
              ? 'Real-time conversational guidance for job seekers.'
              : page === 'Resume'
                ? 'Improve your Resume by getting real time feedback'
                : 'Improve your skills by practicing through mock interviews'}
          </Typography>
          <Typography
            sx={{
              color: '#313131',
              fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'center',
              maxWidth: '800px'
            }}
          >
            {page === 'Chat'
              ? ' This feature is designed to provide real-time conversationalguidance for job seekers. Su engages with users in natural language and offers tailored insights into interview preparation, career advice, and more.'
              : page === 'Resume'
                ? 'Su assists users in enhancing their resumes by analyzing their content and offering feedback on formatting, keyword optimization, and alignment with job descriptions.'
                : 'Su simulates a real interview environment where users can practice both technical and behavioral interview questions, tailored to their desired role.'}
          </Typography>

          <Button
            sx={{
              p: '8px 16px',
              background: '#356EFF',
              color: '#fff',
              width: 'fit-content',
              mt: '8px',
              '&:hover': { background: '#356EFF' },
              '&.Mui-disabled': {
                background: '#b0b0c0', // Lighter gray when disabled
                color: '#e0e0e0', // Light gray for text when disabled
                cursor: 'not-allowed', // Change cursor to indicate disabled state
              },
            }}
            onClick={handleLogin}
          >
            Get started for free
          </Button>

          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              justifyContent: 'flex-start',
              mt: '-8px',
            }}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              sx={{
                color: '#505050',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                fontFamily: 'Montserrat',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                lineHeight: '40px',
              }}
            >
              <CheckIcon height="16px" width="16px" fill="#fff" /> Unlimited
              conversations
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              sx={{
                color: '#505050',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                fontFamily: 'Montserrat',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                lineHeight: '40px',
              }}
            >
              <CheckIcon height="16px" width="16px" fill="#fff" /> free resume
              analysis
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              sx={{
                color: '#505050',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                fontFamily: 'Montserrat',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                lineHeight: '40px',
              }}
            >
              <CheckIcon height="16px" width="16px" fill="#fff" /> 3 free job
              description analysis
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        ref={boxRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '60%',
          height: 'auto',
        }}
      >
        {page === 'Chat' && (
          <video
            ref={videoRef}
            src={'chatWithSu.mp4'}
            autoPlay
            loop
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        {page === 'Resume' && (
          <video
            ref={videoRef}
            src={'ResumeAnalysis.mp4'}
            autoPlay
            loop
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        {page === 'Mock' && (
          <video
            ref={videoRef}
            src={'Feedback.mp4'}
            autoPlay
            loop
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Page5;
