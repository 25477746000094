import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Tooltip,
} from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import {
  isToday,
  isYesterday,
  isThisWeek,
} from 'date-fns';
import BurgerIcon from '../assets/BurgerIcon';
import NewConversationIcon from '../assets/NewConversationIcon';
import ConversationIcon from '../assets/ConversationIcon';
import MockInterviewIcon from '../assets/MockInterviewIcon';

function SideBar({ onNewChat, toggleDrawer, user, userConversations, setUserConversations, setIsChatOpen, scene }) {
  const [conversations, setConversations] = useState({
    today: [],
    yesterday: [],
    lastweek: [],
    beforelastweek: [],
  });

  const conversationDays = [
    'Today',
    'Yesterday',
    'Last Week',
    'Before Last Week',
  ];
  // const navigate = useNavigate();

  useEffect(() => {
    async function fetchConversations() {
      const conversationsRef = collection(db, 'conversation');

      try {
        const q = query(
          conversationsRef,
          where('userId', '==', user.uid) // Order by createdAt in descending order
        );
        const querySnapshot = await getDocs(q);

        const conversations = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const timestamp = data.createdAt ? data.createdAt.toDate() : null;

          return {
            id: doc.id,
            ...data,
            timestamp, // Convert Firestore Timestamp to JS Date, or null if undefined
          };
        });

        const conversationsList = conversations.sort((a, b) => {
          return b.timestamp - a.timestamp;
        });

        const today = [];
        const yesterday = [];
        const lastweek = [];
        const beforelastweek = [];

        conversationsList.forEach((conversation) => {
          if (isToday(conversation.timestamp)) {
            today.push(conversation);
          } else if (isYesterday(conversation.timestamp)) {
            yesterday.push(conversation);
          } else if (isThisWeek(conversation.timestamp)) {
            lastweek.push(conversation);
          } else {
            beforelastweek.push(conversation);
          }
        });

        setConversations({ today, yesterday, lastweek, beforelastweek });
      } catch (error) {
        console.error('Error fetching conversations: ', error);
      }
    }

    fetchConversations();
  }, [user, scene]);

  return (
    <Box
      sx={{
        width: '100%',
        // minWidth:'200px',
        background: '#F7F7F7',
        height: '100%',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '90%',
          display: 'flex',
          background: '#F7F7F7',
          flexDirection: 'column',
          borderRadius: '8px',
          gap: '40px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '12px 16px',
          }}
        >
          <BurgerIcon height="36px" width="36px" toggleDrawer={toggleDrawer} />
          <Tooltip title={'Start conversation'}>
            <Box onClick={onNewChat}>
              <NewConversationIcon height="36px" width="36px" />
            </Box>
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '10px',
            padding: '0 16px',
            overflowX: 'hidden',
            overflowY: 'scroll',
            flexGrow: 1, // Ensure the box takes up available space
            minHeight: 0, // Allows the box to shrink to fit content
            '&::-webkit-scrollbar': {
              width: '12px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#cacbcc',
              borderRadius: '6px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
        >
          {conversationDays.length > 0 &&
            conversationDays.map((timeline) => {
              const conversationList =
                conversations[timeline.toLowerCase().replace(/\s+/g, '')];
              if (!conversationList.length) {
                return null; // or handle this case as needed
              }
              return (
                <Box
                  key={timeline} // Add a unique key to the Box component
                  sx={{
                    display: 'flex',
                    alignItems: 'left',
                    flexDirection: 'column',
                    gap: '10px',
                    padding: '0 16px',
                    width: '100%',
                  }}
                >
                  <Typography
                    noWrap
                    sx={{
                      width: '100%',
                      color: '#908EA3',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '18px',
                      textAlign: 'left',
                    }}
                  >
                    {timeline}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    {conversationList.length > 0 &&
                      conversationList.map((conversation, index) => {
                        let firstMessage = 'Hello there!';
                        const userMessage = conversation.transcript.find(
                          (message) => message.source === 'user'
                        );
                        if (userMessage) {
                          firstMessage = userMessage.text;
                        }

                        return (
                          <Box
                            key={index} // Add a unique key for each conversation
                            sx={{
                              display: 'flex',
                              width: '100%',
                              alignItems: 'center',
                              gap: '10px',
                              maxWidth: '250px',
                              padding: '8px 12px',
                              borderRadius: '8px',
                              '&:hover': {
                                cursor: 'pointer',
                                background: '#ededed',
                              }
                            }}
                            onClick={() => {
                              setUserConversations(conversation.transcript);
                              setIsChatOpen(true);
                            }}
                          >
                            {conversation && conversation.type === 'conversation' ?
                              <ConversationIcon
                                width="20px"
                                height="20px"
                                fill="#ADADAD"
                              />
                              :
                              <MockInterviewIcon
                                width="20px"
                                height="20px"
                                fill="#ADADAD"
                              />
                            }
                            <Typography
                              noWrap
                              sx={{
                                width: 'fit-content',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: '#505050',
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '20px',
                              }}
                            >
                              {firstMessage}
                            </Typography>
                          </Box>
                        );
                      })}
                  </Box>
                  <Divider />
                </Box>
              );
            })}
        </Box>
      </Box>

      <Box
        sx={{
          padding: '24px 16px',
        }}
      >
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: '#356EFF',
            borderRadius: '32px',
            textTransform: 'none',
            padding: '12px 18px',
            color: '#fff',
            fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '20px',
            '&:hover': {
              background: '#356EFF',
            },
          }}
        >
          Upgrade to Plus+
        </Button>
      </Box>
    </Box>
  );
}

export default SideBar;
