import { SvgIcon } from '@mui/material';
import React from 'react';

const LandingIcon4 = ({ width, height }) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
      }}
    >
      <svg
        width="136"
        height="122"
        viewBox="0 0 136 122"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_758_1552)">
          <path
            d="M68.333 110C67.5192 110 66.8594 109.34 66.8594 108.526V88.0664C66.8594 87.2525 67.519 86.5928 68.333 86.5928C69.1469 86.5928 69.8067 87.2525 69.8067 88.0664V108.526C69.8065 109.34 69.1469 110 68.333 110Z"
            fill="#373842"
          />
          <path
            d="M49.0708 109.496C48.8361 109.496 48.5982 109.439 48.377 109.321C47.6594 108.937 47.3887 108.044 47.7727 107.327L58.4497 87.3717C58.8337 86.6539 59.7268 86.3832 60.4442 86.7676C61.1618 87.1515 62.0462 86.9552 61.6623 87.6727L50.3714 108.717C50.1059 109.213 49.5968 109.496 49.0708 109.496Z"
            fill="#373842"
          />
          <path
            d="M87.5954 109.496C87.0693 109.496 86.5603 109.213 86.2946 108.717L75.0231 87.7055C74.6391 86.9879 75.5043 87.1515 76.2219 86.7676C76.9393 86.383 77.8326 86.6543 78.2163 87.3717L88.8933 107.327C89.2773 108.044 89.0067 108.937 88.2891 109.321C88.0682 109.439 87.8297 109.496 87.5954 109.496Z"
            fill="#373842"
          />
          <path
            d="M77.2823 88.0667H59.3839C58.3521 88.0667 57.5156 87.2303 57.5156 86.1984V81.6221H79.1507V86.1984C79.1507 87.2303 78.3142 88.0667 77.2823 88.0667Z"
            fill="#464754"
          />
          <path
            d="M79.1493 81.2373H57.5146V84.5687H79.1493V81.2373Z"
            fill="#373842"
          />
          <path
            d="M81.8868 18.1183H54.7793V17.6647C54.7793 14.5361 57.3156 12 60.444 12H76.2222C79.3507 12 81.8868 14.5361 81.8868 17.6647V18.1183Z"
            fill="#464754"
          />
          <path
            d="M55.4146 15.0586C55.0096 15.8388 54.7793 16.7244 54.7793 17.6642V18.1178H81.8868V17.6642C81.8868 16.7244 81.6566 15.839 81.2515 15.0586H55.4146Z"
            fill="#373842"
          />
          <path
            d="M112.466 81.6217H24.2002C22.253 81.6217 20.6743 80.0432 20.6743 78.0958V21.2212C20.6743 19.274 22.2528 17.6953 24.2002 17.6953H112.466C114.413 17.6953 115.992 19.2738 115.992 21.2212V78.096C115.992 80.0432 114.413 81.6217 112.466 81.6217Z"
            fill="#EFF2FA"
          />
          <path
            d="M112.466 17.6953H104.18C104.18 46.4776 80.8475 69.8103 52.0651 69.8103H20.6743V78.0958C20.6743 80.043 22.2528 81.6217 24.2002 81.6217H112.466C114.413 81.6217 115.992 80.0432 115.992 78.0958V21.2212C115.992 19.2738 114.413 17.6953 112.466 17.6953Z"
            fill="#D7DEED"
          />
          <path
            d="M60.3782 32.0365C51.616 31.9768 44.3795 39.1088 44.3173 47.871C44.2813 52.9429 46.6136 57.4706 50.2729 60.4165C49.924 62.1872 49.2169 64.3505 47.7862 66.0842C47.3633 66.5966 47.7722 67.3563 48.4314 67.2748C49.1677 67.1837 49.9891 67.0261 50.8644 66.7635C51.8897 66.4559 54.4766 65.6451 56.6496 63.5246C57.9426 63.8245 59.2956 63.9673 60.6879 63.9313C69.1741 63.7127 76.0607 56.7712 76.2152 48.2834C76.3773 39.3758 69.2351 32.0968 60.3782 32.0365Z"
            fill="#859FFF"
          />
          <path
            d="M62.2353 44.3438H53.7876C52.9737 44.3438 52.314 43.684 52.314 42.8701C52.314 42.0563 52.9736 41.3965 53.7876 41.3965H62.2353C63.0492 41.3965 63.709 42.0563 63.709 42.8701C63.709 43.684 63.0492 44.3438 62.2353 44.3438Z"
            fill="#5E82DD"
          />
          <path
            d="M61.5183 49.9932H53.2461C52.4322 49.9932 51.7725 49.3334 51.7725 48.5195C51.7725 47.7057 52.432 47.0459 53.2461 47.0459H61.5183C62.3322 47.0459 62.9919 47.7057 62.9919 48.5195C62.9919 49.3334 62.3322 49.9932 61.5183 49.9932Z"
            fill="#6F8EEF"
          />
          <path
            d="M62.7691 55.6416H53.7876C52.9737 55.6416 52.314 54.9818 52.314 54.168C52.314 53.3541 52.9736 52.6943 53.7876 52.6943H62.7691C63.583 52.6943 64.2428 53.3541 64.2428 54.168C64.2428 54.9818 63.583 55.6416 62.7691 55.6416Z"
            fill="#6F8EEF"
          />
          <path
            d="M59.3917 48.1006C59.4989 53.9907 62.4673 59.2232 66.9603 62.4522C72.3304 59.9479 76.1009 54.5374 76.2146 48.2838C76.3366 41.5895 72.3329 35.8159 66.5769 33.334C62.1277 36.6968 59.2819 42.0688 59.3917 48.1006Z"
            fill="#6F8EEF"
          />
          <path
            d="M77.3548 32.0365C86.117 31.9768 93.3535 39.1088 93.4157 47.871C93.4517 52.9429 91.1194 57.4706 87.4601 60.4165C87.809 62.1872 88.5161 64.3505 89.9468 66.0842C90.3696 66.5966 89.9608 67.3563 89.3016 67.2748C88.5653 67.1837 87.7439 67.0261 86.8686 66.7635C85.8433 66.4559 83.2564 65.6451 81.0834 63.5246C79.7904 63.8245 78.4374 63.9673 77.0451 63.9313C68.5589 63.7127 61.6723 56.7712 61.5178 48.2834C61.3559 39.3758 68.498 32.0968 77.3548 32.0365Z"
            fill="#FFC06C"
          />
          <path
            d="M93.3428 49.5078C88.7547 55.4521 82.9125 60.377 76.2109 63.8874C76.4873 63.909 76.7653 63.9245 77.0451 63.9318C78.4374 63.9676 79.7904 63.8248 81.0834 63.5251C83.2564 65.6457 85.8433 66.4565 86.8686 66.764C87.7439 67.0267 88.5654 67.1842 89.3016 67.2753C89.9608 67.3568 90.3696 66.5971 89.9468 66.0847C88.5161 64.3508 87.8088 62.1877 87.4601 60.417C90.7209 57.7917 92.9261 53.9103 93.3428 49.5078Z"
            fill="#FFA75F"
          />
          <path
            d="M85.7802 44.0986H69.771C68.9571 44.0986 68.2974 43.4389 68.2974 42.625C68.2974 41.8111 68.957 41.1514 69.771 41.1514H85.7802C86.5941 41.1514 87.2539 41.8111 87.2539 42.625C87.2539 43.4389 86.5943 44.0986 85.7802 44.0986Z"
            fill="#FFA75F"
          />
          <path
            d="M85.7802 49.7471H69.771C68.9571 49.7471 68.2974 49.0873 68.2974 48.2734C68.2974 47.4596 68.957 46.7998 69.771 46.7998H85.7802C86.5941 46.7998 87.2539 47.4596 87.2539 48.2734C87.2539 49.0873 86.5943 49.7471 85.7802 49.7471Z"
            fill="#FF9255"
          />
          <path
            d="M78.0352 55.3955H69.771C68.9571 55.3955 68.2974 54.7357 68.2974 53.9219C68.2974 53.108 68.957 52.4482 69.771 52.4482H78.0352C78.849 52.4482 79.5088 53.108 79.5088 53.9219C79.5086 54.7357 78.849 55.3955 78.0352 55.3955Z"
            fill="#FFA75F"
          />
          <path
            d="M37.3642 27.0908H28.3262C27.5123 27.0908 26.8525 26.4311 26.8525 25.6172C26.8525 24.8033 27.5121 24.1436 28.3262 24.1436H37.3642C38.178 24.1436 38.8378 24.8033 38.8378 25.6172C38.8378 26.4311 38.1782 27.0908 37.3642 27.0908Z"
            fill="#AFB9D2"
          />
          <path
            d="M37.3642 33.9346H28.3262C27.5123 33.9346 26.8525 33.2748 26.8525 32.4609C26.8525 31.6471 27.5121 30.9873 28.3262 30.9873H37.3642C38.178 30.9873 38.8378 31.6471 38.8378 32.4609C38.8378 33.2748 38.1782 33.9346 37.3642 33.9346Z"
            fill="#C7CFE2"
          />
          <path
            d="M108.49 74.54H99.4517C98.6378 74.54 97.978 73.8803 97.978 73.0664C97.978 72.2526 98.6376 71.5928 99.4517 71.5928H108.49C109.304 71.5928 109.963 72.2526 109.963 73.0664C109.963 73.8803 109.304 74.54 108.49 74.54Z"
            fill="#C7CFE2"
          />
        </g>
        <defs>
          <clipPath id="clip0_758_1552">
            <rect
              width="98"
              height="98"
              fill="white"
              transform="translate(19.333 12)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default LandingIcon4;
