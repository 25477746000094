import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Slide, TextField } from '@mui/material';
import SideBar from './SideBar';
import Header from './Header';
import Emily from '../emily/Emily';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import CrossIcon from '../assets/CrossIcon';
import SendIcon from '@mui/icons-material/Send';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs } from '@firebase/firestore';
import ChatMessage from './ChatMessage';
import { useLocation } from 'react-router-dom';

function Dashboard() {
  const [user, setUser] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const navigate = useNavigate();
  const containerRef = React.useRef(null);
  const [info, setInfo] = useState('');
  const [conversations, setConversations] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isMicOn, setIsMicOn] = useState(false);
  const [scene, setScene] = useState(null);

  const location = useLocation();
  const path = location.pathname.split('/').pop();
  const page = path === 'conversation' ? 'conversation' : 'mockInterview';

  const emilyConnectRef = useRef(null);
  const textCurrentRef = useRef(null);
  const saveAndDisconnectRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/');
      } else {
        setUser(user);
        if (page === 'mockInterview') {
          const userId = user.uid;
          const jobDescriptionRef = collection(db, 'jobDescriptionData');
          const fetchData = async () => {
            try {
              // Fetch all documents in the 'jobDescriptionTable' collection
              const querySnapshot = await getDocs(jobDescriptionRef);
              let userJobDescription = null;

              querySnapshot.forEach((doc) => {
                const data = doc.data();

                if (data && data.userId === userId) {
                  userJobDescription = data;
                }
              });

              if (userJobDescription) {
                const text = `Job Title: ${userJobDescription.jobTitle} \n` +
                  `Company Name: ${userJobDescription.companyName} \n`
                  + `Job Description: ${userJobDescription.jobDescription} \n`
                  + `Resume: ${userJobDescription.resume} \n`
                  + `About ${user.displayName}: ${userJobDescription.aboutYou} \n`
                  + `Please conduct the interview by asking 10 questions related to the information above. 
                      Start by asking the first question and then wait for the candidate's response before 
                      moving on to the next question. Continue this process for all 10 questions.\n`
                  + `After all 10 questions have been asked and answered, provide feedback on the interview session. 
                      Include the following in your feedback:\n`
                  + `Areas for improvement. \n`
                  + `Strong points demonstrated during the interview.\n`
                  + `Suggestions on how the candidate can improve for a real job interview. \n`
                  + 'Please proceed with the first question.';
                setInfo(text);
              }
            } catch (error) {
              console.error('Error fetching job description data:', error); // Handle any errors
            }
          };

          fetchData();
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleNewChat = () => {
    if (emilyConnectRef.current) {
      emilyConnectRef.current();
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleMessageFunction = () => {
    if (inputText && textCurrentRef.current) {
      textCurrentRef.current();
      setInputText('');
    }
  }

  const handleSaveAndDisconnectFunction = () => {
    if (saveAndDisconnectRef.current) {
      saveAndDisconnectRef.current();
    }
  }
  useEffect(() => {
  }, [scene])

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        width: '100vw',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '98%',
          overflow: 'hidden',
          gap: '10px',
          paddingX: '16px',
          alignItems: 'center',
          width: '100%',
        }}
        ref={containerRef}
      >
        <Slide
          in={isDrawerOpen}
          direction="right"
          container={containerRef.current}
        >
          <Box
            item
            sx={{
              width: {
                xs: '40%',   // For screens 600px and below
                sm: '30%',   // For screens 600px and above
                md: '20%',   // For screens 960px and above
                lg: '18%',   // For screens 1200px and above
              },
              height: '100%',
              position: 'relative', // Ensure it stays in place
              display: isDrawerOpen ? 'block' : 'none',
            }}
          >
            <SideBar
              onNewChat={handleNewChat}
              toggleDrawer={toggleDrawer}
              user={user}
              userConversations={conversations}
              setUserConversations={setConversations}
              setIsChatOpen={setIsChatOpen}
              scene={scene}
            />
          </Box>
        </Slide>
        <Box sx={{
          height: '100%', width: isDrawerOpen ? {
            xs: '60%',   // For screens 600px and below
            sm: '70%',   // For screens 600px and above
            md: '80%',   // For screens 960px and above
            lg: '82%',   // For screens 1200px and above
          } : '100%'
        }}>
          <Box sx={{ height: '11%' }}>
            <Header
              page={page}
              isDrawerOpen={isDrawerOpen}
              toggleDrawer={toggleDrawer}
              toggleChat={toggleChat}
              isChatOpen={isChatOpen}
              setIsChatOpen={setIsChatOpen}
              user={user}
              saveAndDisconnect={handleSaveAndDisconnectFunction}
              isMicOn={isMicOn}
              setIsMicOn={setIsMicOn}
              scene={scene}
            />
          </Box>
          <Box
            sx={{
              height: '89%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                width: isChatOpen ? '59%' : '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Emily
                setConnectRef={(connectFn) =>
                  (emilyConnectRef.current = connectFn)
                }
                isChatOpen={isChatOpen}
                toggleChat={toggleChat}
                user={user}
                inputText={inputText}
                setInputText={setInputText}
                info={info}
                conversations={conversations}
                setConversations={setConversations}
                textCurrentRef={(connectFn) => (textCurrentRef.current = connectFn)}
                saveAndDisconnectRef={(connectFn) => (saveAndDisconnectRef.current = connectFn)}
                isMicOn={isMicOn}
                setIsMicOn={setIsMicOn}
                scene={scene}
                setScene={setScene}
              />
            </Box>
            {isChatOpen && (
              <Box
                sx={{
                  width: '40%',
                  height: '96%',
                  border: '1px solid rgba(238, 238, 238, 1)',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    padding: '8px 16px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <CrossIcon
                    width="36px"
                    height="36px"
                    toggleChat={toggleChat}
                    isChatOpen={isChatOpen}
                  />
                </Box>
                <Box
                  sx={{
                    padding: '8px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#9292b1',
                      borderRadius: '6px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: 'rgb(96, 96, 130)',
                    },
                  }}
                >
                  {conversations &&
                    conversations.map((message, index) => {
                      return (
                        <ChatMessage
                          text={message.text}
                          sender={message.source}
                        />
                      );
                    })}
                </Box>
                <Box
                  sx={{
                    display: scene ? 'flex' : 'none',
                    alignItems: 'center',
                    padding: '10px 20px',
                    borderRadius: '20px',
                    zIndex: 1000,
                  }}
                >
                  <TextField
                    fullWidth

                    placeholder="Type your message..."
                    sx={{
                      backgroundColor: '#F0F0FF',
                      borderTopLeftRadius: '5px',
                      borderBottomLeftRadius: '5px',
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '0px',
                      height: '40px',
                      maxWidth: '600px',
                      display: 'flex',
                      alignItems: 'center',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 'inherit', // Inherit the radius from parent
                        '& fieldset': {
                          border: 'none', // Remove border
                        },
                        '&:hover fieldset': {
                          border: 'none', // No border on hover
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none', // No border on focus
                        },
                        '&.MuiInputBase-input': {
                          padding: 0, // Remove padding inside the input
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        height: '100%', // Ensure the input fills the height of the TextField
                        borderTopLeftRadius: '5px',
                        borderBottomLeftRadius: '5px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                      },
                    }}
                    onChange={(e) => setInputText(e.target.value)}
                    value={inputText}
                  />

                  <IconButton
                    color="primary"
                    aria-label="send"

                    sx={{
                      backgroundColor: '#6C68FF',
                      padding: '0px 10px',
                      height: '40px',
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px',
                      textTransform: 'none',
                      boxShadow: 'none', // Remove the box-shadow
                      '&:hover': {
                        backgroundColor: '#6C68FF', // Same background on hover
                        boxShadow: 'none', // No box-shadow on hover

                      },
                      '&:active': {
                        backgroundColor: '#6C68FF', // Same background on click
                        boxShadow: 'none', // No box-shadow on click
                      },
                    }}
                    onClick={handleMessageFunction}
                  >
                    <SendIcon sx={{ color: 'white' }} />
                  </IconButton>

                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;
