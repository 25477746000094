import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Google as GoogleIcon } from '@mui/icons-material';
import Circle from '../../assets/Circle';
import EmailIcon from '../../assets/EmailIcon';
import LinkedIn from '../../assets/LinkedIn';

const Page6 = ({ handleLogin }) => {
  return (
    <Box
      sx={{
        background: '#356EFF',
        m: '12px',
        borderRadius: '16px',
        p: '80px 64px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '1400px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '40px',
                textAlign: 'left',
              }}
            >
              HiSu
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'left',
              }}
            >
              Empowering individuals for landing jobs.
            </Typography>
            <Button
              sx={{
                p: '8px 16px',
                background: '#fff',
                color: '#356FFF',
                width: 'fit-content',
                '&:hover': { background: '#fff' },
                '&.Mui-disabled': {
                  background: '#b0b0c0', // Lighter gray when disabled
                  color: '#e0e0e0', // Light gray for text when disabled
                  cursor: 'not-allowed', // Change cursor to indicate disabled state
                },
              }}
              onClick={handleLogin}
            >
              Get started for free
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '32px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '30px',
                textAlign: 'left',
              }}
            >
              Information
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
              }}
            >
              About us
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '30px',
                textAlign: 'left',
              }}
            >
              Helpful links
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
              }}
            >
              Support
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
              }}
            >
              Terms & Conditions
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
              }}
            >
              Privacy Policy
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '30px',
                textAlign: 'left',
              }}
            >
              Contact us
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
              }}
            >
              +1 999 9999 999
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
              }}
            >
              HiSusupport@emailid.com
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <EmailIcon width="48px" height="48px" />
              <LinkedIn width="48px" height="48px" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Page6;
