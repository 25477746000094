import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CheckIcon from '../../assets/CheckIcon';

const Page1 = ({ handleLogin }) => {
  return (
    <Box
      sx={{
        background: '#fff',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        mt:'30px'
      }}
    >
      <Box
        sx={{
          py: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '40%',
            pl: '60px',
            gap: '16px',
          }}
        >
          <Typography
            sx={{
              color: '#505050',
              fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
              fontSize: '48px',
              fontWeight: 500,
              lineHeight: '60px',
              textAlign: 'left',
            }}
          >
            Improve your interview skills and land your dream job with{' '}
            <span style={{ color: '#6135FF' }}>your personal AI mentor</span>
          </Typography>
          <Typography
            sx={{
              color: '#505050',
              fontFamily: 'Poppins, sans-serif', // Include a fallback font for better compatibility
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '22px',
              textAlign: 'left',
            }}
          >
            Boost your confidence and ace your job interviews with{' '}
            <span style={{ color: '#7D7BB3' }}>your personal AI mentor</span>
          </Typography>

          <Button
            sx={{
              p: '8px 16px',
              background: '#356EFF',
              color: '#fff',
              width: 'fit-content',
              '&:hover': { background: '#356EFF' },
              '&.Mui-disabled': {
                background: '#b0b0c0', // Lighter gray when disabled
                color: '#e0e0e0', // Light gray for text when disabled
                cursor: 'not-allowed', // Change cursor to indicate disabled state
              },
            }}
            onClick={handleLogin}
          >
            Get started for free
          </Button>
          <Box>
            <Box
              sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-start' }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                sx={{
                  color: '#505050',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '20px',
                  fontFamily: 'Montserrat',
                  display:'flex',
                  alignItems:'center',
                  gap:'4px',
                  lineHeight: '40px',
                }}
              >
                <CheckIcon height="16px" width="16px" fill="#fff" /> Unlimited
                conversations
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                sx={{
                  color: '#505050',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '20px',
                  fontFamily: 'Montserrat',
                  display:'flex',
                  gap:'4px',
                  alignItems:'center',
                  lineHeight: '40px',
                }}
              >
                <CheckIcon height="16px" width="16px" fill="#fff" /> free resume
                analysis
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                sx={{
                  color: '#505050',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '20px',
                  fontFamily: 'Montserrat',
                  display:'flex',
                  gap:'4px',
                  alignItems:'center',
                  lineHeight: '40px',
                }}
              >
                <CheckIcon height="16px" width="16px" fill="#fff" /> 3 free job
                description analysis
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '60%',
            height: 'auto',
          }}
        >
          <img
            src="landing1.png"
            alt="Su2"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Page1;
