import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import LandingIcon1 from '../../assets/LandingIcon1';
import CircleIcon from '@mui/icons-material/Circle';

const Page2 = () => {
  const [value, setValue] = React.useState('chat');
  return (
    <Box
      sx={{
        mt: '20px',
        display: 'flex',
        px: '60px',
        gap: '32px',
        justifyContent: 'center',
        my: '60px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '35%',
          px: '12px',
          gap: '32px',
        }}
      >
        <Typography
          sx={{
            color: '#505050',
            fontWeight: '500',
            fontSize: '32px',
            lineHeight: '40px',
            fontFamily: 'Montserrat',
            textAlign: 'left',
          }}
        >
          Su is your AI-powered mentor designed to revolutionize the way you
          prepare for job interviews.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            py: '16px',
          }}
        >
          <Typography
            sx={{
              color: '#505050',
              fontWeight: '500',
              fontSize: '24px',
              lineHeight: '30px',
              fontFamily: 'Montserrat',
              textAlign: 'left',
            }}
          >
            Interactive Learning
          </Typography>
          <Typography
            sx={{
              color: '#505050',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '18px',
              fontFamily: 'Montserrat',
              textAlign: 'left',
            }}
          >
            From answering behavioral questions to discussing technical skills.
            These prompts are designed to be interactive, making the learning
            process more dynamic and personalized.
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            py: '16px',
          }}
        >
          <Typography
            sx={{
              color: '#505050',
              fontWeight: '500',
              fontSize: '24px',
              lineHeight: '30px',
              fontFamily: 'Montserrat',
              textAlign: 'left',
            }}
          >
            Real-Time Feedback
          </Typography>
          <Typography
            sx={{
              color: '#505050',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '18px',
              fontFamily: 'Montserrat',
              textAlign: 'left',
            }}
          >
            Instant feedback on your responses, highlighting strengths and areas
            for improvement. This helps you adjust your strategy on the spot,
            making each practice session more effective.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            py: '16px',
          }}
        >
          <Typography
            sx={{
              color: '#505050',
              fontWeight: '500',
              fontSize: '24px',
              lineHeight: '30px',
              fontFamily: 'Montserrat',
              textAlign: 'left',
            }}
          >
            Confidence Boost
          </Typography>
          <Typography
            sx={{
              color: '#505050',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '18px',
              fontFamily: 'Montserrat',
              textAlign: 'left',
            }}
          >
            Su incorporates techniques from cognitive behavioral therapy to help
            manage anxiety. Calming affirmations,ensuring that you feel
            emotionally prepared as well as technically ready.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '40%',
          height: 'auto',
        }}
      >
        <img
          src="landing3.png"
          alt="Su2"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
    </Box>
  );
};

export default Page2;
