import { SvgIcon } from '@mui/material';
import React from 'react';

const ConversationIcon = ({ width, height, fill = '#505050', mr = 0 }) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
        fill,
        '&:hover': {
          cursor: 'pointer',
        },
        mr,
      }}
    >
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_577_751)">
          <path
            d="M8.24203 6.55463C3.71887 6.55463 4.11365e-05 9.66506 4.11365e-05 13.4885C4.11365e-05 15.0168 0.592854 16.57 1.68 17.7739L0.144807 19.5282C-0.00624793 19.701 -0.0428886 19.9465 0.0526974 20.1559C0.147697 20.3653 0.355979 20.4998 0.585979 20.4998H8.82797C12.1676 20.4998 16.484 17.624 16.484 13.4885C16.484 9.66506 12.7652 6.55463 8.24203 6.55463ZM4.68742 14.5624C4.04141 14.5624 3.51559 14.0366 3.51559 13.3906C3.51559 12.7446 4.04145 12.2188 4.68742 12.2188C5.3334 12.2188 5.85926 12.7446 5.85926 13.3906C5.8593 14.0366 5.33344 14.5624 4.68742 14.5624ZM8.24203 14.5624C7.59602 14.5624 7.0702 14.0366 7.0702 13.3906C7.0702 12.7446 7.59606 12.2188 8.24203 12.2188C8.88801 12.2188 9.41387 12.7446 9.41387 13.3906C9.41387 14.0366 8.88805 14.5624 8.24203 14.5624ZM11.7966 14.5624C11.1506 14.5624 10.6248 14.0366 10.6248 13.3906C10.6248 12.7446 11.1507 12.2188 11.7966 12.2188C12.4426 12.2188 12.9685 12.7446 12.9685 13.3906C12.9685 14.0366 12.4427 14.5624 11.7966 14.5624Z"
            fill={fill}
          />
          <path
            d="M19.8579 12.423L18.4784 10.8134C19.4631 9.70224 19.9993 8.34724 19.9993 6.9454C19.9993 3.39153 16.5821 0.500244 12.3823 0.500244C8.58441 0.500244 5.35781 2.867 4.78809 5.94751C5.84777 5.58743 7.03617 5.38274 8.24176 5.38274C13.3727 5.38274 17.5883 8.96653 17.6499 13.3906H19.4134C19.9139 13.3906 20.1827 12.8012 19.8579 12.423Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_577_751">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default ConversationIcon;
