import { SvgIcon } from '@mui/material';
import React from 'react';

const MockInterviewIcon = ({ width, height, fill = '#505050', mr = 0 }) => {
  return (
    <SvgIcon sx={{ width, height, fill, mr }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_461_3947)">
          <path
            d="M17.7721 3.69287C17.7721 5.03931 16.6807 6.13077 15.3344 6.13077C13.9879 6.13077 12.8965 5.03931 12.8965 3.69287C12.8965 2.34659 13.9879 1.25513 15.3344 1.25513C16.6807 1.25513 17.7721 2.34659 17.7721 3.69287Z"
            fill="#505050"
          />
          <path
            d="M11.1153 6.85074L9.95819 5.11566V2.15515C9.95819 0.973816 9.00543 0 7.8035 0H2.15515C0.95932 0 0 0.968018 0 2.15515V5.60669C0 6.79108 0.955811 7.76169 2.15515 7.76169H10.6277C11.0945 7.76169 11.3747 7.23984 11.1153 6.85074ZM4.96277 5.56519H3.11279C2.79724 5.56519 2.52579 5.32242 2.51114 5.00717C2.49573 4.67056 2.76337 4.39331 3.09647 4.39331H4.9794C5.31189 4.39331 5.58014 4.67056 5.56458 5.00702C5.55008 5.32242 5.27847 5.56519 4.96277 5.56519ZM6.86218 3.36838H3.1131C2.7977 3.36838 2.5264 3.12622 2.51129 2.81128C2.49512 2.47467 2.76306 2.1965 3.09647 2.1965H6.84555C7.16125 2.1965 7.43286 2.43927 7.44736 2.75467C7.46292 3.09113 7.19467 3.36838 6.86218 3.36838Z"
            fill="#505050"
          />
          <path
            d="M4.55017 14.5373C2.02652 14.546 0 16.6294 0 19.153V19.2815C0 19.6784 0.321655 20.0001 0.718536 20.0001H8.414C8.81088 20.0001 9.13254 19.6784 9.13254 19.2815V19.1035C9.13254 16.5761 7.07947 14.5284 4.55017 14.5373Z"
            fill="black"
          />
          <path
            d="M7.44214 11.6619C7.44214 13.2498 6.15491 14.5372 4.56677 14.5372C2.97879 14.5372 1.69141 13.2498 1.69141 11.6619C1.69141 10.0739 2.97879 8.7865 4.56677 8.7865C6.15491 8.7865 7.44214 10.0739 7.44214 11.6619Z"
            fill="black"
          />
          <path
            d="M19.0325 9.41409H11.6367C11.8548 7.56534 13.427 6.13086 15.3348 6.13086C17.2422 6.13086 18.8145 7.56534 19.0325 9.41409Z"
            fill="black"
          />
          <path
            d="M19.4138 10.625C19.7375 10.625 19.9998 10.8873 19.9998 11.2109V18.159C19.9998 18.4918 19.722 18.7602 19.3853 18.7442C19.0705 18.7292 18.8279 18.4578 18.8279 18.1422V17.4895H10.0728C9.67902 16.1488 8.80835 15.0098 7.65723 14.2711C8.25323 13.566 8.61349 12.655 8.61349 11.6617C8.61349 11.3036 8.5665 10.9563 8.47861 10.625H19.4138Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_461_3947">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default MockInterviewIcon;
