import React from 'react';
import { SvgIcon } from '@mui/material';

const BackIcon = ({ width, height }) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_458_1184)">
          <path
            d="M18 4C10.2801 4 4 10.2801 4 18C4 25.7199 10.2801 32 18 32C25.7199 32 32 25.7199 32 18C32 10.2801 25.7199 4 18 4ZM21.1581 23.0085C21.6143 23.4646 21.6143 24.202 21.1581 24.6581C20.9306 24.8856 20.632 25 20.3333 25C20.0346 25 19.7359 24.8856 19.5084 24.6581L13.6751 18.8249C13.219 18.3687 13.219 17.6314 13.6751 17.1752L19.5085 11.3419C19.9646 10.8857 20.702 10.8857 21.1581 11.3419C21.6143 11.798 21.6143 12.5354 21.1581 12.9915L16.1496 18L21.1581 23.0085Z"
            fill="#ADADAD"
          />
        </g>
        <defs>
          <clipPath id="clip0_458_1184">
            <rect
              width="28"
              height="28"
              fill="white"
              transform="translate(4 4)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default BackIcon;
