import { SvgIcon } from '@mui/material';
import React from 'react';

const EmailIcon = ({ width, height }) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
      }}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="white" />
        <path
          d="M33.8906 15.5625H14.1094C12.9439 15.5625 12 16.5123 12 17.6719V30.3281C12 31.4946 12.9509 32.4375 14.1094 32.4375H33.8906C35.0463 32.4375 36 31.4986 36 30.3281V17.6719C36 16.5143 35.0598 15.5625 33.8906 15.5625ZM33.5952 16.9688C33.1642 17.3974 25.7476 24.775 25.4916 25.0297C25.0931 25.4281 24.5634 25.6475 24 25.6475C23.4366 25.6475 22.9069 25.4281 22.5071 25.0284C22.3349 24.8571 15.0001 17.561 14.4048 16.9688H33.5952ZM13.4062 30.0419V17.959L19.483 24.0037L13.4062 30.0419ZM14.4057 31.0312L20.4801 24.9955L21.5141 26.0241C22.1781 26.6881 23.061 27.0538 24 27.0538C24.939 27.0538 25.8219 26.6881 26.4846 26.0254L27.5199 24.9955L33.5943 31.0312H14.4057ZM34.5938 30.0419L28.517 24.0037L34.5938 17.959V30.0419Z"
          fill="#356EFF"
        />
      </svg>
    </SvgIcon>
  );
};

export default EmailIcon;
