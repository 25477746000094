import React, { useEffect, useState } from "react";
import UploadResume from "./UploadJobDescription";
import UploadResumeNext from "./UploadJobDescriptionNext";

import { collection, addDoc, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

const UploadIndex = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [jobTitle, setJobTitle] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [resume, setResume] = useState('');
    const [aboutYou, setAboutYou] = useState('');
    const [user, setUser] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                const userId = user.uid;
                const jobDescriptionRef = collection(db, 'jobDescriptionData');
                const fetchData = async () => {
                    try {
                        const querySnapshot = await getDocs(jobDescriptionRef);
                        let userJobDescription = null;

                        querySnapshot.forEach((doc) => {
                            const data = doc.data();

                            // Check if the 'userId' matches the authenticated user's ID
                            if (data && data.userId === userId) {
                                userJobDescription = data;  // Store the user's job description data
                            }
                        });

                        if (userJobDescription) {
                            // Set state with the data from Firestore
                            setJobTitle(userJobDescription.jobTitle);
                            setCompanyName(userJobDescription.companyName);
                            setJobDescription(userJobDescription.jobDescription);
                            setResume(userJobDescription.resume);
                            setAboutYou(userJobDescription.aboutYou); // Update the state to indicate that the description already exists
                        }
                    } catch (error) {
                        console.error('Error fetching job description data:', error);  // Handle any errors
                    }
                };

                fetchData();
            } else {
                navigate('/');
            }
        });

        // Clean up the listener on component unmount
        return () => unsubscribe();
    }, [navigate, user]);

    const handleSubmit = async () => {
        if (!user) return;  // Ensure user is defined

        const data = {
            jobTitle: jobTitle,
            companyName: companyName,
            jobDescription: jobDescription,
            resume: resume,
            aboutYou: aboutYou,
            userId: user.uid,
        };

        const jobDescriptionRef = collection(db, 'jobDescriptionData');

        try {
            // Create a query to check if a document with the current user's ID already exists
            const querySnapshot = await getDocs(query(jobDescriptionRef, where('userId', '==', user.uid)));

            if (!querySnapshot.empty) {
                // Document exists, update it
                const docId = querySnapshot.docs[0].id; // Get the document ID
                const docRef = doc(db, 'jobDescriptionData', docId); // Get a reference to the document
                await updateDoc(docRef, data); // Update the document with new data
            } else {
                // Document does not exist, create a new one
                await addDoc(jobDescriptionRef, data);
            }

            // Reset form fields and navigate
            setActiveStep(0);
            setJobTitle('');
            setCompanyName('');
            setJobDescription('');
            setResume('');
            setAboutYou('');
            navigate('/mock-interview');

        } catch (error) {
            console.error("Error adding or updating document: ", error);
        }
    }
    return (
        <div>
            {activeStep === 0 ? (
                <UploadResume
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    setJobTitle={setJobTitle}
                    jobTitle={jobTitle}
                    setCompanyName={setCompanyName}
                    companyName={companyName}
                    setJobDescription={setJobDescription}
                    jobDescription={jobDescription}
                />
            ) : (
                <UploadResumeNext
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    jobTitle={jobTitle}
                    setJobTitle={setJobTitle}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    jobDescription={jobDescription}
                    setJobDescription={setJobDescription}
                    resume={resume}
                    setResume={setResume}
                    aboutYou={aboutYou}
                    setAboutYou={setAboutYou}
                    handleSubmit={handleSubmit}
                />
            )}
        </div>
    );
}

export default UploadIndex;