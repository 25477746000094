import { SvgIcon } from '@mui/material';
import React from 'react';

const CrossIcon = ({
  width,
  height,
  fill = '#505050',
  toggleChat,
  isChatOpen,
}) => {
  return (
    <SvgIcon
      sx={{
        width,
        height,
        fill,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => {
        isChatOpen && toggleChat();
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_475_1663)">
          <path
            d="M27.9039 8.0963C22.4414 2.63489 13.5577 2.63489 8.09516 8.0963C2.63495 13.5577 2.63495 22.4437 8.09516 27.9051C10.8264 30.6352 14.4133 31.9997 18.0001 31.9997C21.587 31.9997 25.1727 30.6352 27.9039 27.9051C33.3654 22.4437 33.3654 13.5577 27.9039 8.0963ZM23.7767 22.1274C24.233 22.5837 24.233 23.3214 23.7767 23.7778C23.549 24.0054 23.2503 24.1198 22.9514 24.1198C22.6526 24.1198 22.3538 24.0054 22.1262 23.7778L18.0001 19.6505L13.8752 23.7766C13.6464 24.0042 13.3476 24.1186 13.0499 24.1186C12.7511 24.1186 12.4523 24.0042 12.2247 23.7766C11.7683 23.3202 11.7683 22.5814 12.2247 22.1262L16.3496 18.0001L12.2235 13.874C11.7672 13.4176 11.7672 12.6788 12.2235 12.2236C12.6787 11.7672 13.4176 11.7672 13.874 12.2236L18 16.3497L22.1261 12.2236C22.5825 11.7672 23.3202 11.7672 23.7765 12.2236C24.2329 12.6788 24.2329 13.4176 23.7765 13.874L19.6505 18.0001L23.7767 22.1274Z"
            fill="#FF766D"
          />
        </g>
        <defs>
          <clipPath id="clip0_475_1663">
            <rect
              width="28"
              height="28"
              fill="white"
              transform="translate(4 4)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default CrossIcon;
