import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ChatMessage = ({ sender, text }) => {
  return (
    <Box
      sx={{
        maxWidth: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: sender === 'persona' ? 'left' : 'right',
      }}
    >
      <Box
        sx={{
          maxWidth: '70%',
          width: '100%',
          display: 'flex',
          gap: '16px',
          justifyContent: sender === 'persona' ? 'left' : 'right',
        }}
      >
        {sender === 'persona' && (
          <Avatar
            src={'./Su.jpg'}
            sx={{ marginLeft: '10px', width: '32px', height: '32px' }}
          >
            <AccountCircleIcon style={{ width: '100%', height: '100%' }} />
          </Avatar>
        )}
        <Typography
          sx={{
            width: '90%',
            padding: '12px',
            background:
              sender !== 'persona' ? 'rgba(243, 243, 243, 1)' : '#F7F7F7',
            borderRadius: '8px',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '14px',
            textAlign: 'left',
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatMessage;
